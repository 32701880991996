import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const contractSlice = createSlice({
  name: 'contractSlice',
  initialState: {
    list: loadDataPaginatedState(),
    item: loadDataInitialState(null),
    contractInvoices: loadDataInitialState([]),
    contractTasks: loadDataInitialState([]),
  },
  reducers: {
    replaceContract(state, action) {
      const index = state.list.data.content.findIndex((i) => i.id === action.payload.data.id);
      state.list.data.content[index] = action.payload.data;
    },
    clearItemWithInitialState(state, action) {
      state[action.payload.collection] = loadDataInitialState(action.payload.data);
    },

    /**  --- Add contract to contracts list if the contract is out loaded contract list with pagination.
     *  Just for Autocomplete --- */
    setContracts(state, action) {
      state.list.data.content = action.payload;
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchContracts, 'list');
    loadDataExtraReducer(builder, thunks.fetchContractInvoices, 'contractInvoices');
    loadDataExtraReducer(builder, thunks.fetchContractTasks, 'contractTasks');
    loadDataExtraReducer(builder, thunks.fetchContract, 'item');
    loadDataExtraReducer(builder, thunks.createContract, 'item');
    loadDataExtraReducer(builder, thunks.updateContract, 'item');
  },
});

export const { replaceContract, setContracts, clearItemWithInitialState } = contractSlice.actions;
export default contractSlice.reducer;
