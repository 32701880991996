import { memo } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AttentionIcon, QuestionIcon } from 'theme/icons';


const Modal = ({
  open,
  onClose,
  title,
  children,
  actions,
  maxWidth = 'sm',
  fullWidth = false,
  questionIcon,
  attentionIcon,
  ...rest
}) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth={maxWidth} fullWidth={fullWidth} {...rest}>
      {title && (
        <DialogTitle>
          {
            <Stack justifyContent="center" alignItems="center" sx={{ gap: 3, pt: 3 }}>
              {questionIcon && <QuestionIcon />}
              {attentionIcon && <AttentionIcon/>}
              <Typography variant="subtitle1" color="text.main">
                {title}
              </Typography>
            </Stack>
          }
        </DialogTitle>
      )}
      <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: 'primary.main' }}>
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ p: 0 }}>{children}</DialogContent>
      {actions && <DialogActions sx={{ p: 3, justifyContent:'center' }}>{actions}</DialogActions>}
    </Dialog>
  );
};

export default memo(Modal);
