import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

import LegalEntityForm from './legalEntities/LegalEntityForm';
import ComplexDictionaryItem from './ComplexDictionaryItem';
import RenderLegalEntityItem from './legalEntities/RenderLegalEntityItem';

const ComplexDictionary = ({ selectedDictionary }) => {
  const itemsList = useSelector(selectedDictionary.selector);
  const { t } = useTranslation();

  const [editMode, setEditMode] = useState({ trigger: false, item: null });

  const enableEditMode = (item) => {
    setEditMode({ item: item, trigger: true });
  };
  const disableEditMode = () => {
    setEditMode({ trigger: false, item: null });
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
        <Typography textAlign="center" variant="h6" color="primary.main">
          {t('types.settings.dictionaries.listOf')}{' '}
          {t(`types.settings.dictionaries.listPlural.${selectedDictionary?.name}`)}
        </Typography>
        <IconButton sx={{ color: 'primary.main' }} onClick={() => enableEditMode({ item: null })}>
          <AddCircleOutline fontSize="small" />
        </IconButton>
      </Stack>
      <Divider sx={{ borderColor: 'secondary.light' }} />
      {editMode?.trigger === true && (
        <Stack
          sx={{
            border: editMode?.trigger === true ? '1px dashed' : '1px solid',
            borderColor: 'primary.light',
            borderRadius: 1,
            p: 1,
            position: 'relative',
            '&:hover': {
              boxShadow: '0px 0px 2px rgba(77, 84, 201, 0.7)',
            },
          }}
        >
          <LegalEntityForm disableEditMode={disableEditMode} t={t} />
        </Stack>
      )}
      <Stack height="100%" sx={{ overflowY: 'auto', gap: 1 }}>
        {itemsList?.length === 0 ? (
          <Typography variant="h6" color="text.main">
            {selectedDictionary.label} {t('messages.warning.empty')}
          </Typography>
        ) : (
          itemsList.map((item) => (
            <ComplexDictionaryItem key={item.id} item={item} renderItem={<RenderLegalEntityItem item={item} />} />
          ))
        )}
      </Stack>
    </>
  );
};

export default memo(ComplexDictionary);
