import { Button, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as ExportIcon } from '../../theme/icons/exportIcon.svg';
import { memo } from 'react';
import SearchBar from './SearchBar';
import { exportToExcel } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { tableSelectors } from 'store/ducks/table';
import TasksFilter from 'views/pages/tasks/components/TasksFilter';

import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useTranslation } from 'react-i18next';

const HeaderList = ({
  title,
  to,
  buttonTitle,
  hasPermission,
  filter,
  handleFilter,
  pageView,
  bulkButtonTitle,
  bulkTo,
  isSearchbarShow,
  togglePageView,
}) => {
  /** --- Getting selected data from state --- */
  const selectedData = useSelector(tableSelectors.getSelected());
  const { t } = useTranslation();
  const theme = useTheme();

  const handleExport = () => {
    exportToExcel(selectedData);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap={4}
      py={1}
      px={2}
      sx={{
        backgroundColor: 'extra.white',
        // p: 1,
        // pl: 3,
        borderBottomLeftRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
      }}
    >
      <Stack gap={2} direction="row" alignItems="center">
        <Typography variant="h4" color="text.main">
          {title}
        </Typography>

        <Stack direction="row" justifyContent="center" alignItems="center">
          {pageView && (
            <Tooltip
              title={pageView === 'list' ? t('base.tooltips.switchToColView') : t('base.tooltips.switchToListView')}
            >
              <IconButton onClick={togglePageView} sx={{ color: 'primary.main' }}>
                {pageView === 'list' ? <ViewListIcon /> : <GridViewOutlinedIcon />}
              </IconButton>
            </Tooltip>
          )}
          {filter && <TasksFilter filter={filter} handleFilter={handleFilter} pageView={pageView} />}
          <Stack />
        </Stack>
      </Stack>
      {isSearchbarShow && <SearchBar />}
      <Stack direction="row" gap={2}>
        {hasPermission && buttonTitle && (
          <Button LinkComponent={Link} variant="contained" to={to}>
            {buttonTitle}
          </Button>
        )}
        {hasPermission && bulkButtonTitle && (
          <Button LinkComponent={Link} variant="contained" to={bulkTo}>
            {bulkButtonTitle}
          </Button>
        )}

        <Button
          disabled={selectedData?.length === 0 ? true : false}
          startIcon={
            <ExportIcon
              fill={selectedData?.length === 0 ? theme.palette.action.disabled : theme.palette.primary.main}
            />
          }
          onClick={handleExport}
        >
          {t('base.buttons.export')}
        </Button>
      </Stack>
    </Stack>
  );
};
export default memo(HeaderList);
