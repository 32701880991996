import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const gradeSlice = createSlice({
  name: 'gradeSlice',
  initialState: {
    list: loadDataPaginatedState(),
    item: loadDataInitialState(null),
  },
  reducers: {
    replaceGrade(state, action) {
      const index = state.list.data.content.findIndex((i) => i.id === action.payload.data.id);
      state.list.data.content[index] = action.payload.data;
    },
    clearItemWithInitialState(state, action) {
      state[action.payload.collection] = loadDataInitialState(action.payload.data)
    }
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchGrades, 'list');
    loadDataExtraReducer(builder, thunks.fetchGrade, 'item');
  },
});

export const { replaceGrade, clearItemWithInitialState } = gradeSlice.actions;
export default gradeSlice.reducer;
