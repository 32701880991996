import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';

import { DictionaryListItemButton, DictionaryListItemIcon } from 'views/common/StyledComponents';
import LegalEntityForm from './legalEntities/LegalEntityForm';

const ComplexDictionaryItem = ({ item, renderItem }) => {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation();

  const enableEditMode = (item) => {
    setEditMode({ item: item, trigger: true });
  };
  const disableEditMode = () => {
    setEditMode(false);
  };

  return (
    <Stack
      key={item.id}
      sx={{
        border: editMode ? '1px dashed' : '1px solid',
        borderColor: 'primary.light',
        borderRadius: 1,
        p: 1,
        position: 'relative',
        '&:hover': {
          boxShadow: '0px 0px 2px rgba(77, 84, 201, 0.7)',
        },
      }}
    >
      {editMode && <LegalEntityForm disableEditMode={disableEditMode} legalEntity={item} t={t} />}
      {!editMode && (
        <Stack direction="row" alignItems="flex-start" gap={4} width="100%">
          {renderItem}
          <DictionaryListItemButton>
            <DictionaryListItemIcon onClick={enableEditMode}>
              <EditOutlined fontSize="small" />
            </DictionaryListItemIcon>
          </DictionaryListItemButton>
        </Stack>
      )}
    </Stack>
  );
};

export default memo(ComplexDictionaryItem);
