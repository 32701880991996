import { Table, TableRow, TableCell, Paragraph, WidthType, AlignmentType, TextRun, VerticalAlign } from 'docx';
import filters from 'filters';

export const createGradesTable = (grades = []) => {
  const headerRow = new TableRow({
    children: [
      new TableCell({
        spacing: {
          before: 150,
        },
        width: {
          size: 4505,
          type: WidthType.DXA,
        },
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: 'Assignment', bold: true })],
          }),
        ],
        verticalAlign: VerticalAlign.CENTER,
      }),
      new TableCell({
        width: {
          size: 4505,
          type: WidthType.DXA,
        },
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: 'Deadline', bold: true })],
          }),
        ],
        verticalAlign: VerticalAlign.CENTER,
      }),
      new TableCell({
        width: {
          size: 4505,
          type: WidthType.DXA,
        },
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: 'Submission date', bold: true })],
          }),
        ],
        verticalAlign: VerticalAlign.CENTER,
      }),
      new TableCell({
        width: {
          size: 4505,
          type: WidthType.DXA,
        },
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: 'Grade', bold: true })],
          }),
        ],
        verticalAlign: VerticalAlign.CENTER,
      }),
    ],
  });

  const gradeRows = grades?.map((grade) => {
    return new TableRow({
      children: [
        new TableCell({
          width: {
            size: 4505,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [new TextRun({ text: grade.homeworkName })],
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          width: {
            size: 4505,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [new TextRun({ text: filters.date(grade.deadline) })],
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          width: {
            size: 4505,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [new TextRun({ text: filters.date(grade.submission) })],
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          width: {
            size: 4505,
            type: WidthType.DXA,
          },
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [new TextRun({ text: grade.grade ? String(grade.grade) : '' })],
            }),
          ],
          verticalAlign: VerticalAlign.CENTER,
        }),
      ],
    });
  });

  return new Table({
    columnWidths: [4505, 4505, 4505, 4505],
    rows: [headerRow, ...gradeRows],
  });
};
