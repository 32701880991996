import { memo, useEffect } from 'react';
import { userSelectors } from 'store/ducks/user';
import { useDispatch, useSelector } from 'react-redux';
import { dictionaryThunks } from 'store/ducks/dictionary';
import { useTranslation } from 'react-i18next';

const AppDataContainer = ({ children }) => {
  const user = useSelector(userSelectors.getCurrentUser());
  const dispatch = useDispatch();

  const { i18n } = useTranslation();

  const currentLang = i18n.language;
  const userLanguage = user?.language.name.toLowerCase();

  useEffect(() => {
    if (!userLanguage && !currentLang) {
      i18n.changeLanguage('en');
      return;
    }

    if (currentLang !== userLanguage) {
      i18n.changeLanguage(userLanguage);
      return;
    }
  }, [userLanguage, currentLang, i18n]);

  useEffect(() => {
    if (user?.id) {
      dispatch(dictionaryThunks.fetchEnums());
      dispatch(dictionaryThunks.fetchEducations());
      dispatch(dictionaryThunks.fetchUniversities());
      dispatch(dictionaryThunks.fetchProfessions());
      dispatch(dictionaryThunks.fetchLegalEntities());
      dispatch(dictionaryThunks.fetchRoles());
      dispatch(dictionaryThunks.fetchManagers());
      dispatch(dictionaryThunks.fetchTrainers());
      dispatch(dictionaryThunks.fetchMentors());
      dispatch(dictionaryThunks.fetchCoordinators());
      dispatch(dictionaryThunks.fetchEmployees());
      dispatch(dictionaryThunks.fetchTaskTypes());
    }
  }, [dispatch, user?.id]);

  return children;
};

export default memo(AppDataContainer);
