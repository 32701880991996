import { FilterAltOutlined } from '@mui/icons-material';
import { Button, Menu, Stack, useTheme } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import store from 'store';
import { personActions, personSelectors, personThunks } from 'store/ducks/person';
import { reportActions, reportThunks } from 'store/ducks/report';
import { getFullName } from 'utils/dataTable';
import CustomAutocomplete from 'views/common/filter/CustomAutocomplete';

const StudentGradesFilter = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const clients = useSelector(personSelectors.getClients());
  const clientsLoading = useSelector(personSelectors.clientsLoading());

  const groups = useSelector(personSelectors.getPersonGroups());
  const groupsLoading = useSelector(personSelectors.personGroupsLoading());

  const groupsList = (!groupsLoading && groups.map(({ group }) => group)) || [];

  const [filter, setFilter] = useState({ student: null, group: null });

  useEffect(() => {
    filter.student?.id && store.dispatch(personThunks.fetchPersonGroups(filter.student?.id));
  }, [filter.student?.id]);

  useEffect(() => {
    if (!filter.student) {
      store.dispatch(personActions.clearItemWithInitialState({ collection: 'userGroups', data: [] }));
      setFilter({ ...filter, group: null });
    }
  }, [filter]);

  const fetchClients = (value) => {
    store.dispatch(personThunks.fetchClients(`?q=${value}`));
  };

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilter({ student: null, group: null });
    store.dispatch(reportActions.addFilterStudentGrade(null));
    store.dispatch(reportActions.clearItemWithInitialState({ collection: 'studentGrades', data: [] }));
  };

  const handleGenerate = () => {
    if (!filter.student && !filter.group) return;
    store.dispatch(reportActions.addFilterStudentGrade(filter));
    store.dispatch(reportThunks.fetchStudentGrades({ studentId: filter.student?.id, groupId: filter.group?.id }));
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => {
      store.dispatch(reportActions.addFilterStudentGrade(null));
      store.dispatch(reportActions.clearItemWithInitialState({ collection: 'studentGrades', data: [] }));
    };
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        id="studentGrade-filter-button"
        aria-controls={open ? 'studentGrade-filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickOpen}
      >
        <FilterAltOutlined sx={{ color: theme.palette.primary.main, mr: 1 }} />
        {t('base.buttons.filter')}
      </Button>
      <Menu
        id="studentGrade-filter-menu"
        anchorEl={anchorEl}
        sx={{ mt: 1 }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'studentGrade-filter-button',
          role: 'listbox',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" p={2} gap={2}>
          <CustomAutocomplete
            options={clients.content}
            loading={clientsLoading}
            label={t('base.labels.student')}
            value={filter.student}
            onChange={(value) => setFilter({ ...filter, student: value })}
            fetchOptions={fetchClients}
            getCustomLabel={getFullName}
          />
          <CustomAutocomplete
            options={groupsList}
            loading={groupsLoading}
            label={t('base.labels.group')}
            value={filter.group}
            onChange={(value) => setFilter({ ...filter, group: value })}
          />
          <Stack direction="row" gap={2} mt={2} width="100%">
            <Button sx={{ width: '100%' }} onClick={handleClose}>
              {t('base.buttons.cancel')}
            </Button>
            <Button variant="contained" sx={{ width: '100%' }} onClick={handleGenerate}>
              {t('base.buttons.generate')}
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </>
  );
};

export default memo(StudentGradesFilter);
