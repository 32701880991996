import { Button, Stack, useTheme } from '@mui/material';
import { memo } from 'react';
import ReportsMenu from './ReportsMenu';
import FilterByPerson from './FilterByPerson';
import FilterByPeriod from './FilterByPeriod';
import { useSelector } from 'react-redux';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { reportActions, reportSelectors } from 'store/ducks/report';
import FilterComplex from './FilterComplex';
import TotalSessionsDuration from './TotalSessionsDuration';
import { tableSelectors } from 'store/ducks/table';
import { exportToExcel } from 'utils/helpers';
import { ReactComponent as ExportIcon } from '../../../../theme/icons/exportIcon.svg';
import { useTranslation } from 'react-i18next';
import StudentGradesFilter from './StudentGradesFilter';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { getLastNameAndNameAndSecondNameLetter } from 'utils/dataTable';
import generateStudentGrades from 'services/generateWordDoc/studentsGradesDoc/generateStudentGrades';
import TrainersSessionsFilter from './TrainersSessionsFilter';

const HeaderReports = ({
  overdueInvoicesReport,
  sessionsDurationReport,
  invoicesForPeriod,
  handleGenerateReport,
  studentGradesReport,
  wordDoc,
  trainerSessionsStatistic,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const manager = useSelector(reportSelectors.getFilterByManager());
  const managers = useSelector(dictionarySelectors.getManagers());
  const trainer = useSelector(reportSelectors.getFilterByTrainer());
  const trainers = useSelector(dictionarySelectors.getTrainers());
  const query = useSelector(reportSelectors.getQuery());
  const sessionsDuration = useSelector(reportSelectors.getSessionsDuration());

  const selectedData = useSelector(tableSelectors.getSelected());
  const studentGradesFilter = useSelector(reportSelectors.getStudentGradesFilterData());
  const studentGrades = useSelector(reportSelectors.getStudentGrades());

  const handleExport = () => {
    exportToExcel(selectedData);
  };

  const doc =
    studentGrades.length !== 0 &&
    generateStudentGrades(studentGradesFilter?.student, studentGradesFilter.group?.program, studentGrades);

  const saveDocument = () => {
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${getLastNameAndNameAndSecondNameLetter(studentGradesFilter.student)}-grades.docx`);
    });
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      px={2}
      bgcolor={'extra.white'}
      sx={{ borderBottomLeftRadius: theme.spacing(2), borderBottomRightRadius: theme.spacing(2) }}
    >
      <ReportsMenu />

      <Stack gap={2} direction="row" justifyContent="center" alignItems="center">
        {overdueInvoicesReport && (
          <FilterByPerson
            options={managers}
            person={manager}
            action={reportActions.addFilterByManager}
            label={t('base.labels.responsible')}
          />
        )}
        {sessionsDurationReport && (
          <>
            {sessionsDuration.data && <TotalSessionsDuration sessionsDuration={sessionsDuration.data} />}
            <FilterComplex
              person={trainer}
              options={trainers}
              action={reportActions.addFilterByTrainer}
              handleGenerateReport={handleGenerateReport}
              query={query}
            />
          </>
        )}
        {invoicesForPeriod && <FilterByPeriod />}
        {studentGradesReport && <StudentGradesFilter />}
        {trainerSessionsStatistic && <TrainersSessionsFilter />}
        {wordDoc && (
          <Button onClick={saveDocument} disabled={studentGrades.length === 0} sx={{ color: 'primary.main' }}>
            <FileDownloadOutlined />
            {t('base.buttons.download')}
          </Button>
        )}
        {!wordDoc && (
          <Button
            disabled={selectedData?.length === 0 ? true : false}
            startIcon={
              <ExportIcon
                fill={selectedData?.length === 0 ? theme.palette.action.disabled : theme.palette.primary.main}
              />
            }
            onClick={handleExport}
          >
            {t('base.buttons.export')}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default memo(HeaderReports);
