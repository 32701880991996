import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const groupSlice = createSlice({
  name: 'groupSlice',
  initialState: {
    list: loadDataPaginatedState(),
    item: loadDataInitialState(null),
    groupMembers: loadDataInitialState([]),
    groupSessions: loadDataInitialState([]),
    groupHistory: loadDataInitialState([]),
  },
  reducers: {
    replaceGroup(state, action) {
      const index = state.list.data.content.findIndex((i) => i.id === action.payload.data.id);
      state.list.data.content[index] = action.payload.data;
    },
    clearItemWithInitialState(state, action) {
      state[action.payload.collection] = loadDataInitialState(action.payload.data);
    },
    /**  --- Add group to groups list if the group is out of loaded contract list with pagination.
     *  Just for Autocomplete --- */
    setGroups(state, action) {
      state.list.data.content = action.payload;
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchGroups, 'list');
    loadDataExtraReducer(builder, thunks.fetchGroupMembers, 'groupMembers');
    loadDataExtraReducer(builder, thunks.fetchGroupSessions, 'groupSessions');
    loadDataExtraReducer(builder, thunks.fetchGroupHistory, 'groupHistory');
    loadDataExtraReducer(builder, thunks.fetchGroup, 'item');
    loadDataExtraReducer(builder, thunks.createGroup, 'item');
    loadDataExtraReducer(builder, thunks.updateGroup, 'item');
  },
});

export const { replaceGroup, setGroups, clearItemWithInitialState } = groupSlice.actions;
export default groupSlice.reducer;
