import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';

import { Stack } from '@mui/material';

import store from 'store';
import { assignmentActions, assignmentSelectors, assignmentThunks } from 'store/ducks/assignment';

import Loader from 'views/common/Loader';
import Error from 'views/common/Error';
import AssignmentForm from './components/AssignmentForm';

const AssignmentCard = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const assignment = useSelector(assignmentSelectors.getAssignment());
  const isLoading = useSelector(assignmentSelectors.assignmentLoading()) && !assignment;

  useEffect(() => {
    store.dispatch(assignmentThunks.fetchAssignment(id));

    return () => store.dispatch(assignmentActions.clearItemWithInitialState({ collection: 'item', data: null }));
  }, [id]);

  return (
    <Stack justifyContent="center">
      {isLoading && <Loader />}
      {!isLoading && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <AssignmentForm assignment={assignment} />
        </ErrorBoundary>
      )}
    </Stack>
  );
};

export default memo(AssignmentCard);
