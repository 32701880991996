import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import store from 'store';
import { lessonActions, lessonSelectors, lessonThunks } from 'store/ducks/lesson';

import Loader from 'views/common/Loader';
import LessonForm from './components/LessonForm';
import Error from 'views/common/Error';

import useModal from 'hooks/useModal';

const LessonCard = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [modalOpen, openModal, closeModal] = useModal();
  const lesson = useSelector(lessonSelectors.getLesson());
  const isLoading = useSelector(lessonSelectors.lessonLoading()) && !lesson;

  useEffect(() => {
    store.dispatch(lessonThunks.fetchLesson(id));

    return () => store.dispatch(lessonActions.clearItemWithInitialState({ collection: 'item', data: null }));
  }, [id]);

  return (
    <>
      <Stack justifyContent="center">
        {isLoading && <Loader />}
        {!isLoading && (
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            <LessonForm lesson={lesson} modalOpen={modalOpen} closeModal={closeModal} openModal={openModal} t={t} />
          </ErrorBoundary>
        )}
      </Stack>
    </>
  );
};

export default memo(LessonCard);
