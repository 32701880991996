import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';

/** --- CONTRACTS --- */
const fetchContractsNoGroup = createAsyncThunk('/report/fetchContractsNoGroup', async (query) => {
  return api.get(`contracts/reports/no-group${query}`);
});

const fetchPausedContractsActiveInvoices = createAsyncThunk(
  '/report/fetchPausedContractsActiveInvoices',
  async (query) => {
    return api.get(`contracts/reports/paused/invoices${query}`);
  }
);

const fetchCancelledContractsActiveInvoices = createAsyncThunk(
  '/report/fetchCancelledContractsActiveInvoices',
  async (query) => {
    return api.get(`contracts/reports/cancelled/invoices${query}`);
  }
);

const fetchContractsNoDocs = createAsyncThunk('/report/fetchContractsNoDocs', async (query) => {
  return api.get(`contracts/reports/no-documents${query}`);
});

/** --- INVOICES --- */

const fetchInvoicesForPeriod = createAsyncThunk('report/fetchInvoicesForPeriod', async (query) => {
  return api.get(`/invoices/reports/period${query}`);
});

const fetchOverdueInvoices = createAsyncThunk('report/fetchOverdueInvoices', async (query) => {
  return api.get(`/invoices/reports/overdue${query}`);
});

/** --- SESSIONS --- */

const fetchSessionsWithFilters = createAsyncThunk('report/fetchSessionsWithFilters', async (query) => {
  return api.get(`/sessions/reports/trainer-sessions${query}`);
});

const fetchSessionsDuration = createAsyncThunk('report/fetchSessionsDuration', async (query) => {
  return api.get(`/sessions/reports/trainer-sessions/duration${query}`);
});

const fetchTrainersSessionsStatistic = createAsyncThunk('report/fetchTrainersSessionsStatistic', async (data) => {
  return api.post(`/sessions/reports/trainer-sessions/total${data.query}`, data.body);
});

/** --- STUDENT'S GRADES --- */

const fetchStudentGrades = createAsyncThunk('report/fetchStudentGrades', async (data) => {
  return api.get(`/grades/reports/student-grades/${data.studentId}/groups/${data.groupId}`);
});

export default {
  fetchContractsNoGroup,
  fetchPausedContractsActiveInvoices,
  fetchCancelledContractsActiveInvoices,
  fetchContractsNoDocs,
  fetchInvoicesForPeriod,
  fetchOverdueInvoices,
  fetchSessionsWithFilters,
  fetchSessionsDuration,
  fetchStudentGrades,
  fetchTrainersSessionsStatistic,
};
