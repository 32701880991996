import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Stack, Tab, Tabs, useTheme } from '@mui/material';

import store from 'store';
import { groupActions, groupSelectors, groupThunks } from 'store/ducks/group';

import PersonsList from 'views/common/PersonsList';
import SessionsList from 'views/common/SessionsList';

import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import { TabPanel, a11yProps } from 'utils/tabsFunc';
import { TabPanelsWrapper } from 'views/common/StyledComponents';

const GroupDetails = ({ group }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const members = useSelector(groupSelectors.getGroupMembers());
  const membersLoading = useSelector(groupSelectors.groupMembersLoading());
  const sessions = useSelector(groupSelectors.getGroupSessions());
  const sessionsLoading = useSelector(groupSelectors.groupSessionsLoading());
  const history = useSelector(groupSelectors.getGroupHistory());
  const historyLoading = useSelector(groupSelectors.groupHistoryLoading());
  const [tabsValue, setTabsValue] = useState(0);

  const membersAndHistoryPermission = hasPermission(PERMISSIONS.person.read);
  const sessionPermission = hasPermission(PERMISSIONS.session.read);

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    membersAndHistoryPermission &&
      (store.dispatch(groupThunks.fetchGroupMembers(group.id)),
      store.dispatch(groupThunks.fetchGroupHistory(group.id)));
  }, [group, membersAndHistoryPermission]);

  useEffect(() => {
    sessionPermission && store.dispatch(groupThunks.fetchGroupSessions(group.id));
  }, [group, sessionPermission]);

  useEffect(() => {
    return () => (
      store.dispatch(
        groupActions.clearItemWithInitialState({ collection: 'groupMembers', data: [] })
      ),
      store.dispatch(
        groupActions.clearItemWithInitialState({ collection: 'groupSessions', data: [] })
      ),
      store.dispatch(
        groupActions.clearItemWithInitialState({ collection: 'groupHistory', data: [] })
      )
    );
  }, []);

  const historyMembers = history.map((option) => {
    return { ...option.student, from: option.from, to: option.to };
  });

  return (
    <Stack sx={{ height: '100%', overflowY: 'hidden' }}>
      <Tabs value={tabsValue} onChange={handleChange} aria-label="main data">
        <Tab
          sx={{ width: `calc((100% - ${theme.spacing(4)}) / 3)` }}
          label={t('types.group.members')}
          {...a11yProps(0)}
          disabled={!membersAndHistoryPermission}
        />
        <Tab
          sx={{ width: `calc((100% - ${theme.spacing(4)}) / 3)` }}
          label={t('types.session.namePlural')}
          {...a11yProps(1)}
          disabled={!sessionPermission}
        />
        <Tab
          sx={{ width: `calc((100% - ${theme.spacing(4)}) / 3)` }}
          label={t('types.group.history')}
          {...a11yProps(2)}
          disabled={!membersAndHistoryPermission}
        />
      </Tabs>

      <TabPanelsWrapper>
        <TabPanel value={tabsValue} index={0}>
          <PersonsList
            list={members}
            totalElements={members.length}
            columnVisibilityModel={{
              roles: false,
              idCode: false,
              birthDate: false,
              from: false,
              to: false,
            }}
            isLoading={membersLoading}
            hideFooter
            isNested
          />
        </TabPanel>
        <TabPanel value={tabsValue} index={1}>
          <SessionsList
            list={sessions}
            totalElements={sessions.length}
            isLoading={sessionsLoading}
            columnVisibilityModel={{
              group: false,
            }}
            hideFooter
            isNested
          />
        </TabPanel>
        <TabPanel value={tabsValue} index={2}>
          <PersonsList
            list={historyMembers}
            totalElements={historyMembers.length}
            columnVisibilityModel={{
              roles: false,
              idCode: false,
              birthDate: false,
            }}
            isLoading={historyLoading}
            hideFooter
            isNested
          />
        </TabPanel>
      </TabPanelsWrapper>
    </Stack>
  );
};

export default memo(GroupDetails);
