import { Autocomplete, Checkbox, Chip, debounce, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'theme/icons';
import { memo, useEffect, useRef, useState } from 'react';

const CustomAutocomplete = ({
  options,
  value,
  onChange,
  label,
  selectAllOption,
  getCustomLabel,
  loading,
  fetchOptions,
  multiple,
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const [allSelected, setAllSelected] = useState(false);

  const handleSelectAllClick = () => {
    if (allSelected) {
      onChange([]);
    } else {
      onChange(options);
    }
    setAllSelected(!allSelected);
  };

  const handleOptionChange = (event, newValue) => {
    setAllSelected(newValue.length === options.length);
    onChange(newValue);
  };

  const debouncedFetch = useRef(
    debounce((value) => {
      if (fetchOptions) {
        fetchOptions(value);
      }
    }, 400)
  ).current;

  useEffect(() => {
    if (searchValue) {
      debouncedFetch(searchValue);
    }
  }, [searchValue, debouncedFetch]);

  return (
    <Autocomplete
      options={selectAllOption ? [{ name: 'Select All', id: 'select-all' }, ...options] : options}
      value={value}
      limitTags={2}
      popupIcon={<KeyboardArrowDownIcon sx={{ color: 'primary.lighter' }} />}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.id || option.name}>
            {selectAllOption && (
              <Checkbox sx={{ py: 0 }} checked={selected || (allSelected && option.id !== 'select-all')} />
            )}
            {(getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name) || option.name}
          </li>
        );
      }}
      getOptionLabel={(option) => {
        return getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name || option.name;
      }}
      renderTags={(value, getTagProps) =>
        value.map((option) => (
          <Chip
            deleteIcon={<CloseIcon width={20} height={20} />}
            sx={{
              fontSize: 14,
              m: 0,
              height: 16,
              '& .MuiChip-label': { pl: 0, pr: 1 },
              border: 'none',
            }}
            {...getTagProps({
              label: getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name || option.name,
            })}
            key={option.name || option.id}
            variant="outlined"
            label={getCustomLabel ? getCustomLabel(option) : option.label ? option.label : option.name || option.name}
          />
        ))
      }
      loading={loading}
      onChange={(event, newValue) => {
        if (selectAllOption) {
          if (newValue.some((option) => option.id === 'select-all')) {
            handleSelectAllClick();
          } else {
            handleOptionChange(event, newValue);
          } 
        } else onChange(newValue);
      }}
      onInputChange={(e, value) => {
        setSearchValue(value);
      }}
      multiple={multiple}
      sx={{ width: '100%', maxWidth: 380 }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label={t(label)}
          placeholder={t('base.placeholders.choose')}
        />
      )}
    />
  );
};

export default memo(CustomAutocomplete);
