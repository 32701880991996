import { memo } from 'react';
import { Form, withFormik } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Stack, TextField } from '@mui/material';

import store from 'store';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { groupThunks } from 'store/ducks/group';
import { programActions, programSelectors, programThunks } from 'store/ducks/program';

import { FormColumnTitle, FormColumnWrapper, FormWrapper, PageWrapper } from 'views/common/StyledComponents';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikDatePicker from 'views/form/FormikDatePicker';
import FormikTextField from 'views/form/FormikTextField';
import FormikArrayDateTime from 'views/form/FormikArrayDateTime';
import HeaderCard from 'views/common/HeaderCard';
import GroupDetails from './GroupDetails';

import { PermissionContextProvider } from 'services/context/permissionContext';
import { useAddEntityIfNotExists } from 'hooks/useAddEntityIfNotExists';
import { useAutocompleteSearch } from 'hooks/useAutocompleteSearch';

import { getActivePrograms, getProgramHours, getShortName } from 'utils/dataTable';
import { hasPermission } from 'utils/roles';
import { validationGroupForm } from 'utils/schema';
import { PERMISSIONS } from 'utils/constants/permissions';

const GroupForm = ({ group, modalOpen, openModal, closeModal, isSubmitting, values, isLoading }) => {
  const { t } = useTranslation();
  const programs = useSelector(programSelectors.getPrograms());
  const programsLoading = useSelector(programSelectors.programsLoading());
  const enums = useSelector(dictionarySelectors.getEnums());
  const trainers = useSelector(dictionarySelectors.getTrainers());
  const mentors = useSelector(dictionarySelectors.getMentors());
  const coordinators = useSelector(dictionarySelectors.getCoordinators());

  const permission = group ? hasPermission(PERMISSIONS.group.update) : hasPermission(PERMISSIONS.group.create);

  /** --- Custom hook for handling server search in Autocomplete --- */
  const [setSearchValue] = useAutocompleteSearch(group, values?.program?.name, programThunks.fetchPrograms);

  /** --- Custom hook for handling fetch data and set it in the list of options in Autocomplete --- */
  useAddEntityIfNotExists(values?.program, programSelectors.getPrograms(), programActions.setPrograms);

  return (
    <PageWrapper>
      <PermissionContextProvider value={permission}>
        <Form id="groupForm" style={{ height: '100%' }}>
          <HeaderCard
            title={group ? t('types.group.name') : t('types.group.new')}
            data={group}
            isSubmitting={isSubmitting}
            formId="groupForm"
            modalOpen={modalOpen}
            openModal={openModal}
            closeModal={closeModal}
            hasPermission={permission}
          />
          <Stack height="calc(100% - 60px)" gap={1} sx={{ overflowY: 'auto' }}>
            <FormWrapper>
              <FormColumnWrapper sx={{ width: '33.33%' }}>
                <FormColumnTitle>{t('types.group.info')}</FormColumnTitle>
                <FormikTextField name="name" label={t('base.labels.name')} placeholder={t('base.placeholders.name')} />
                <FormikAutocomplete name="status" label={t('base.labels.status')} options={enums.groupStatuses} />
                <FormikAutocomplete name="format" label={t('base.labels.format')} options={enums.groupFormats} />
                <FormikAutocomplete
                  name="program"
                  label={t('base.labels.program')}
                  onInputChange={(e, value) => {
                    setSearchValue(value);
                  }}
                  /** --- Checking if it is existing group or a new one.
                   * Depending on this condition we filter the options - Active programs or all programs --- */
                  options={group ? programs.content : getActivePrograms(programs.content)}
                  loading={programsLoading}
                  placeholder={t('base.placeholders.typeForSearch')}
                />
                <TextField
                  name="hours"
                  label={t('base.labels.programHours')}
                  placeholder={t('base.placeholders.readOnly')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={getProgramHours(programs, values)}
                />
              </FormColumnWrapper>
              <FormColumnWrapper sx={{ width: '33.33%' }}>
                <FormColumnTitle>{t('types.group.schedule')}</FormColumnTitle>
                <FormikDatePicker name="startDate" label={t('base.labels.startDate')} />
                <FormikArrayDateTime
                  name="schedule"
                  label={t('base.labels.schedule')}
                  values={values.schedule}
                  daysOptions={enums.daysOfWeek}
                />
                <FormikDatePicker name="graduationDate" label={t('base.labels.graduationDate')} />
              </FormColumnWrapper>
              <FormColumnWrapper sx={{ width: '33.33%' }}>
                <FormColumnTitle>{t('types.group.staff')}</FormColumnTitle>
                <FormikAutocomplete
                  name="trainer"
                  label={t('base.labels.trainer')}
                  options={trainers}
                  getCustomLabel={getShortName}
                />
                <FormikAutocomplete
                  name="mentors"
                  label={t('base.labels.mentor')}
                  options={mentors}
                  getCustomLabel={getShortName}
                  multiple
                />
                <FormikAutocomplete
                  name="coordinator"
                  label={t('base.labels.coordinator')}
                  options={coordinators}
                  getCustomLabel={getShortName}
                />
              </FormColumnWrapper>
            </FormWrapper>
            {group && !isLoading && <GroupDetails group={group} groupLoading={isLoading} />}
          </Stack>
        </Form>
      </PermissionContextProvider>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ group = {} }) => ({
      ...group,
      id: group?.id,
      name: group?.name || '',
      program: group?.program || null,
      status: group?.status || null,
      startDate: group?.startDate || null,
      graduationDate: group?.graduationDate || null,
      schedule: group?.schedule || [],
      trainer: group?.trainer || null,
      mentors: group?.mentors || [],
      coordinator: group?.coordinator || null,
      format: group?.format || null,
    }),
    validationSchema: validationGroupForm,
    handleSubmit: async (values, { props, setSubmitting }) => {
      const res = props.group
        ? await store.dispatch(groupThunks.updateGroup(values))
        : await store.dispatch(groupThunks.createGroup(values));

      if (!res.error) {
        toast.success(
          props.group ? props.t('messages.success.toast.updateGroup') : props.t('messages.success.toast.createGroup')
        );
        setSubmitting(false);

        if (props.group) {
          props.closeModal();
        } else props.navigate(`/groups/${res.payload.id}`);
      }
    },
    enableReinitialize: true,
  })(GroupForm)
);
