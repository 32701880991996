import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, List, ListItem, ListItemButton, ListItemText, Stack, Typography } from '@mui/material';

import { PageWrapper } from 'views/common/StyledComponents';
import ComplexDictionary from './components/ComplexDictionary';
import SingleDictionary from './components/SingleDictionary';
import { DICTIONARIES_LIST } from 'utils/constants/constants';

const DictionariesPage = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);

  const handleChange = (newValue) => {
    setSelected(newValue);
  };

  useEffect(() => {
    setSelected(DICTIONARIES_LIST[0]);
  }, []);

  return (
    <PageWrapper sx={{ height: 'calc(100dvh - 79px)' }}>
      <Stack direction="row" spacing={2} sx={{ height: '100%', width: '100%' }}>
        <Stack
          sx={{
            backgroundColor: 'extra.white',
            borderRadius: 2,
            width: '30%',
            px: 2,
            py: 1,
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="center" minHeight="36px">
            <Typography textAlign="center" variant="h6" color="primary.main">
              {t('types.settings.dictionaries.namePlural')}
            </Typography>
          </Stack>
          <Divider sx={{ borderColor: 'secondary.light' }} />
          <List sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, overflowY: 'auto' }}>
            {DICTIONARIES_LIST.map((dictionary) => {
              const menuItemColor = dictionary.name === selected?.name ? 'extra.white' : 'text.dark';
              const menuItemBgColor = dictionary.name === selected?.name ? 'primary.main' : 'transparent';
              return (
                <ListItem key={dictionary.name} disableGutters sx={{ p: 0 }}>
                  <ListItemButton
                    sx={{
                      py: 1,
                      px: 2,
                      backgroundColor: menuItemBgColor,
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: 'primary.lighter',
                      justifyContent: 'center',
                      transition: 'all 0.3s',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        borderColor: 'primary.light',
                        boxShadow: '0px 0px 4px 0px #4D54C91A',
                        '& .MuiListItemText-root': { color: 'primary.main' },
                      },
                    }}
                    onClick={() => handleChange(dictionary)}
                    disableGutters
                  >
                    <ListItemText
                      primary={dictionary.label}
                      sx={{
                        color: menuItemColor,
                        '&>.MuiTypography-root': {
                          fontSize: 16,
                          fontWeight: 500,
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Stack>
        {selected && selected?.type === 'simple' && (
          <Stack
            sx={{
              backgroundColor: 'extra.white',
              borderRadius: 2,
              width: '70%',
              py: 1,
              px: 2,
            }}
          >
            <SingleDictionary selectedDictionary={selected} />
          </Stack>
        )}
        {selected && selected?.type === 'complex' && (
          <Stack
            sx={{
              backgroundColor: 'extra.white',
              borderRadius: 2,
              width: '70%',
              py: 1,
              px: 2,
            }}
          >
            <ComplexDictionary selectedDictionary={selected} />
          </Stack>
        )}
      </Stack>
    </PageWrapper>
  );
};

export default memo(DictionariesPage);
