import { FilterAltOutlined } from '@mui/icons-material';
import { Button, Menu, Stack, useTheme } from '@mui/material';
import filters from 'filters';
import { DateTime } from 'luxon';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import store from 'store';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { reportActions, reportThunks } from 'store/ducks/report';
import { getShortName } from 'utils/dataTable';
import CustomAutocomplete from 'views/common/filter/CustomAutocomplete';
import { PeriodDatePicker } from 'views/common/StyledComponents';

const TrainersSessionsFilter = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const trainers = useSelector(dictionarySelectors.getTrainers());

  const INITIAL_FILTER = {
    trainers: [],
    from: DateTime.local().startOf('month').toFormat('yyyy-MM-dd'),
    to: DateTime.local().endOf('month').toFormat('yyyy-MM-dd'),
  };

  const [filter, setFilter] = useState(INITIAL_FILTER);

  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilter(INITIAL_FILTER);
    store.dispatch(reportActions.clearItemWithInitialState({ collection: 'trainerSessionsStatistic', data: [] }));
  };

  const handleGenerate = async () => {
    if (filter.trainers.length === 0) return;
    await store.dispatch(
      reportThunks.fetchTrainersSessionsStatistic({
        query: `?from=${filter.from}&to=${filter.to}`,
        body: filter.trainers,
      })
    );
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => {
      store.dispatch(reportActions.clearItemWithInitialState({ collection: 'trainerSessionsStatistic', data: [] }));
    };
  }, []);

  return (
    <>
      <Button
        variant="outlined"
        id="trainerSessions-filter-button"
        aria-controls={open ? 'trainerSessions-filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClickOpen}
      >
        <FilterAltOutlined sx={{ color: theme.palette.primary.main, mr: 1 }} />
        {t('base.buttons.filter')}
      </Button>
      <Menu
        id="trainerSessions-filter-menu"
        anchorEl={anchorEl}
        sx={{ mt: 1 }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'trainerSessions-filter-button',
          role: 'listbox',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" p={2} gap={2}>
          <Stack direction="row" gap={2}>
            <PeriodDatePicker
              label={t('base.labels.from')}
              value={DateTime.fromISO(filter?.from) || null}
              onChange={(newValue) => setFilter({ ...filter, from: filters.date(newValue?.ts, 'yyyy-MM-dd') })}
            />
            <PeriodDatePicker
              label={t('base.labels.to')}
              value={DateTime.fromISO(filter?.to) || null}
              onChange={(newValue) => setFilter({ ...filter, to: filters.date(newValue?.ts, 'yyyy-MM-dd') })}
            />
          </Stack>
          <CustomAutocomplete
            options={trainers}
            label={t('base.labels.trainer')}
            value={filter.trainers}
            onChange={(value) => setFilter({ ...filter, trainers: value })}
            getCustomLabel={getShortName}
            multiple
            selectAllOption
          />
          <Stack direction="row" gap={2} mt={2} width="100%">
            <Button sx={{ width: '100%' }} onClick={handleClose}>
              {t('base.buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              sx={{ width: '100%' }}
              onClick={handleGenerate}
              disabled={filter.trainers.length === 0}
            >
              {t('base.buttons.generate')}
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </>
  );
};

export default memo(TrainersSessionsFilter);
