import { memo, useEffect } from 'react';
import { Form, withFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Stack, TextField } from '@mui/material';
import filters from 'filters';

import store from 'store';
import { contractActions, contractSelectors, contractThunks } from 'store/ducks/contract';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { taskThunks } from 'store/ducks/task';

import HeaderCard from 'views/common/HeaderCard';
import { FormColumnTitle, FormColumnWrapper, FormWrapper, PageWrapper } from 'views/common/StyledComponents';
import FilesUploader from 'views/form/FilesUploader';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikDatePicker from 'views/form/FormikDatePicker';
import FormikTextField from 'views/form/FormikTextField';
import FormikTimePicker from 'views/form/FormikTimePicker';

import { PermissionContextProvider } from 'services/context/permissionContext';
import { PERMISSIONS } from 'utils/constants/permissions';
import { getLastNameAndNameAndSecondNameLetter, getShortName } from 'utils/dataTable';
import { uploadFilesAndReturnUploadedFiles } from 'utils/helpers';
import { hasPermission } from 'utils/roles';
import { validationTaskForm } from 'utils/schema';
import FormikAutocompleteMultipleFetch from 'views/form/FormikAutocompleteMultipleFetch';

const TaskForm = ({ task, modalOpen, openModal, closeModal, isSubmitting, values, files, setFiles }) => {
  const { t } = useTranslation();

  const employees = useSelector(dictionarySelectors.getEmployees());
  const employeesLoading = useSelector(dictionarySelectors.employeesLoading()) && !employees;
  const enums = useSelector(dictionarySelectors.getEnums());
  const taskTypes = useSelector(dictionarySelectors.getTaskTypes());
  const taskTypesLoading = useSelector(dictionarySelectors.taskTypesLoading());

  const permission = task ? hasPermission(PERMISSIONS.task.update) : hasPermission(PERMISSIONS.task.create);

  const contracts = useSelector(contractSelectors.getContracts());
  const contractsLoading = useSelector(contractSelectors.contractsLoading());

  const fetchContracts = (value) => {
    store.dispatch(contractThunks.fetchContracts(`?q=${value}`));
  };

  useEffect(() => {
    if (task) {
      const existing = Array.isArray(values.contracts) ? values.contracts : [values.contracts];
      const newEntities = existing.filter((e) => e?.id && !contracts.content.find((en) => en.id === e?.id));

      if (newEntities.length > 0) {
        const updatedEntities = [...contracts.content, ...newEntities];
        store.dispatch(contractActions.setContracts(updatedEntities));
      }
    }
  }, [task, contracts.content, values.contracts]);

  const getContractNameForTask = (option) => {
    return option ? getLastNameAndNameAndSecondNameLetter(option.student) : '';
  };

  return (
    <PageWrapper>
      <PermissionContextProvider value={permission}>
        <Form id="taskForm">
          <HeaderCard
            title={task ? t('types.task.name') : t('types.task.new')}
            data={task}
            isSubmitting={isSubmitting}
            formId="taskForm"
            modalOpen={modalOpen}
            openModal={openModal}
            closeModal={closeModal}
            hasPermission={permission}
          />
          <Stack height="calc(100% - 60px)">
            <FormWrapper>
              <FormColumnWrapper sx={{ width: '50%' }}>
                <FormColumnTitle>{t('base.dictionary.data')}</FormColumnTitle>
                <FormikTextField name="name" placeholder={t('base.placeholders.name')} label={t('base.labels.name')} />
                <FormikTextField
                  name="description"
                  placeholder={t('base.placeholders.description')}
                  label={t('base.labels.description')}
                  multiline
                  rows={3}
                />
                <FormikAutocomplete
                  name="responsible"
                  label={t('base.labels.responsible')}
                  getCustomLabel={getShortName}
                  options={employees || []}
                  loading={employeesLoading}
                  multiple
                  limitTags={2}
                />
                <Stack direction="row" alignItems="center" gap={2} width="100%">
                  <FormikAutocomplete
                    name="status"
                    label={t('base.labels.status')}
                    options={enums.taskStatuses || []}
                    sx={{ width: '30%' }}
                  />
                  <FormikAutocomplete
                    name="type"
                    label={t('base.labels.type')}
                    options={taskTypes || []}
                    loading={taskTypesLoading}
                    sx={{ width: '70%' }}
                  />
                </Stack>

                <Stack direction="row" alignItems="center" gap={1} width="100%">
                  <FormikDatePicker name="dueDate" label={t('base.labels.dueDateTask')} sx={{ width: '50%' }} />
                  <FormikTimePicker
                    name="dueTime"
                    useTimezone
                    date={values.dueDate}
                    timeZone={values.timezone}
                    label={t('base.labels.dueTimeTask')}
                    sx={{ width: '50%' }}
                  />
                </Stack>
              </FormColumnWrapper>
              <FormColumnWrapper sx={{ width: '50%' }}>
                <FormColumnTitle>{t('types.task.about')}</FormColumnTitle>
                <TextField
                  name="author"
                  label={t('base.labels.author')}
                  placeholder={t('base.placeholders.readOnly')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={getShortName(values?.author)}
                />
                <TextField
                  name="createdAt"
                  label={t('base.labels.date')}
                  placeholder={t('base.placeholders.readOnly')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={filters.date(values?.createdAt, 'dd.MM.yyyy HH:mm')}
                />

                <FormikAutocompleteMultipleFetch
                  name="contracts"
                  label={t('base.labels.contract')}
                  getCustomLabel={getContractNameForTask}
                  options={contracts.content || []}
                  fetchOptions={fetchContracts}
                  loading={contractsLoading}
                  limitTags={2}
                  multiple
                  pathForOpenEntity="/contracts/"
                />
                <FilesUploader
                  files={files}
                  setFiles={setFiles}
                  fileSize="15000000"
                  filesAccept=".pdf, .ppt, .pptx, .txt, .xls, .xlsx, .doc, .docx, .zip, .mp4, .mpeg, .avi, .jpeg, .jpg, .png"
                  isLoading={isSubmitting}
                  uploadedFiles={values?.files}
                />
              </FormColumnWrapper>
            </FormWrapper>
          </Stack>
        </Form>
      </PermissionContextProvider>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ task = {} }) => ({
      ...task,
      id: task?.id,
      author: task?.author,
      name: task?.name || '',
      description: task?.description || '',
      responsible: task?.responsible || [],
      status: task?.status || null,
      createdAt: task?.createdAt,
      dueDate: task?.dueDate || null,
      dueTime: task?.dueTime || null,
      files: task?.files || [],
      contracts: task?.contracts || [],
      timezone: task?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      type: task?.type || null,
    }),
    validationSchema: validationTaskForm,
    handleSubmit: async (values, { props, setSubmitting }) => {
      /** -- upload files and waiting response with uploaded files -- */
      const uploadedFiles = await uploadFilesAndReturnUploadedFiles(props.files, values);
      /**  -- update values with uploaded files data ---- */
      const updatedValues = { ...values, files: uploadedFiles.filter((file) => file !== '') }; // remove empty items from array which were added by null trigger

      const response = props.task
        ? await store.dispatch(taskThunks.updateTask(updatedValues))
        : await store.dispatch(taskThunks.createTask(updatedValues));

      if (!response.error) {
        toast.success(
          props.task ? props.t('messages.success.toast.updateTask') : props.t('messages.success.toast.createTask')
        );
        setSubmitting(false);

        if (props.task) {
          props.closeModal();
        } else props.navigate(`/tasks/${response.payload.id}`);
      }
    },
    enableReinitialize: true,
  })(TaskForm)
);
