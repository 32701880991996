import { Autocomplete, Button, Chip, IconButton, Link, TextField, Tooltip } from '@mui/material';
import { useField } from 'formik';
import { memo, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CloseIcon, LinkOpenIcon } from 'theme/icons';
import { useTranslation } from 'react-i18next';
import useModal from 'hooks/useModal';
import Modal from 'views/common/modal/Modal';

const FormikAutocompleteMultipleFetch = ({
  name,
  options,
  label,
  multiple,
  getCustomLabel,
  loading,
  fetchOptions,
  fontSize = 16,
  pathForOpenEntity,
  ...rest
}) => {
  const { t } = useTranslation();

  const [field, meta, helpers] = useField(name);
  const [searchValue, setSearchValue] = useState('');
  const debouncedFetch = useRef(debounce(fetchOptions, 400)).current;
  const showError = meta.touched && Boolean(meta.error);
  const [modalOpen, openModal, closeModal] = useModal();
  const [optionToDelete, setOptionToDelete] = useState(null);

  useEffect(() => {
    if (searchValue) {
      debouncedFetch(searchValue);
    }
  }, [searchValue, debouncedFetch]);

  const handleTagDelete = () => {
    const updatedValue = field.value.filter((option) => option.id !== optionToDelete.id);
    helpers.setValue(updatedValue);
    closeModal();
  };

  const handleOpenModal = (option) => {
    setOptionToDelete(option);
    openModal();
  };

  return (
    <>
      <Autocomplete
        multiple={multiple}
        options={options}
        value={field.value || []}
        onChange={(_, value) => {
          helpers.setValue(value !== null ? value : []);
        }}
        onInputChange={(e, value) => {
          setSearchValue(value);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (getCustomLabel ? getCustomLabel(option) : option.label || option.name)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              deleteIcon={<CloseIcon width={20} height={20} />}
              sx={{
                fontSize: 14,
                m: 0,
                height: 0,
                '& .MuiChip-label': { pl: 0, pr: 1 },
                border: 'none',
              }}
              {...getTagProps({ index })}
              key={option.id}
              variant="outlined"
              label={
                <span>
                  {getCustomLabel ? getCustomLabel(option) : option.label || option.name}
                  <Tooltip title={t('base.tooltips.openLink')}>
                    <IconButton
                      sx={{ p: 0.5 }}
                      component={Link}
                      href={`${pathForOpenEntity}${option.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkOpenIcon width={18} height={18} />
                    </IconButton>
                  </Tooltip>
                </span>
              }
              onDelete={() => handleOpenModal(option)}
            />
          ))
        }
        loading={loading}
        popupIcon={<KeyboardArrowDownIcon sx={{ color: 'primary.lighter' }} />}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={rest.placeholder || 'Choose'}
            InputLabelProps={{ shrink: true }}
            label={label}
            name={name}
            error={showError}
            helperText={(meta.touched && meta.error) || ''}
            sx={{ '& input': { fontSize } }}
          />
        )}
      />
      <Modal
        title={`${t('base.dictionary.delete')} ${
          getCustomLabel ? getCustomLabel(optionToDelete) : optionToDelete.label || optionToDelete.name
        }? `}
        open={modalOpen}
        onClose={closeModal}
        attentionIcon
        actions={
          <>
            <Button sx={{ px: 8 }} onClick={closeModal}>
              {t('base.buttons.cancel')}
            </Button>
            <Button variant="contained" sx={{ px: 8 }} type="button" onClick={handleTagDelete} color="error">
              {t('base.buttons.delete')}
            </Button>
          </>
        }
      />
    </>
  );
};

export default memo(FormikAutocompleteMultipleFetch);
