import { memo, useEffect } from 'react';
import { Form, withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import store from 'store';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { lessonThunks } from 'store/ducks/lesson';
import { programActions, programSelectors, programThunks } from 'store/ducks/program';

import HeaderCard from 'views/common/HeaderCard';
import { FormColumnTitle, FormColumnWrapper, FormWrapper, PageWrapper } from 'views/common/StyledComponents';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikTextField from 'views/form/FormikTextField';

import { PermissionContextProvider } from 'services/context/permissionContext';
import { useAddEntityIfNotExists } from 'hooks/useAddEntityIfNotExists';
import { useAutocompleteSearch } from 'hooks/useAutocompleteSearch';
import { hasPermission } from 'utils/roles';
import { validationLessonForm } from 'utils/schema';
import { getActivePrograms, getNameAndNumber } from 'utils/dataTable';
import { PERMISSIONS } from 'utils/constants/permissions';

const LessonForm = ({ lesson, modalOpen, openModal, closeModal, isSubmitting, values, setFieldValue }) => {
  const { t } = useTranslation();

  const enums = useSelector(dictionarySelectors.getEnums());
  const programs = useSelector(programSelectors.getPrograms());
  const programsLoading = useSelector(programSelectors.programsLoading());

  const permission = lesson ? hasPermission(PERMISSIONS.lesson.update) : hasPermission(PERMISSIONS.lesson.create);

  /** --- Custom hook for handling server search in Autocomplete --- */
  const [setSearchValue] = useAutocompleteSearch(lesson, values?.program?.name, programThunks.fetchPrograms);

  /** --- Custom hook for handling fetch data and set it in the list of options in Autocomplete --- */
  useAddEntityIfNotExists(values?.program, programSelectors.getPrograms(), programActions.setPrograms);

  useEffect(() => {
    !values.program && setFieldValue('module', null);
  }, [values.program, setFieldValue]);

  return (
    <PageWrapper>
      <PermissionContextProvider value={permission}>
        <Form id="lessonForm" style={{ height: '100%' }}>
          <HeaderCard
            title={lesson ? t('types.lesson.name') : t('types.lesson.new')}
            data={lesson}
            isSubmitting={isSubmitting}
            formId="lessonForm"
            modalOpen={modalOpen}
            openModal={openModal}
            closeModal={closeModal}
            hasPermission={permission}
          />
          <FormWrapper>
            <FormColumnWrapper sx={{ width: '50%' }}>
              <FormColumnTitle> {t('types.lesson.info')}</FormColumnTitle>
              <FormikTextField
                name="number"
                label={t('base.labels.number')}
                placeholder={t('base.placeholders.number')}
              />

              <FormikTextField name="name" label={t('base.labels.name')} placeholder={t('base.placeholders.name')} />
              <FormikAutocomplete name="type" label={t('base.labels.type')} options={enums.lessonTypes} />
              <FormikAutocomplete
                name="program"
                label={t('base.labels.program')}
                /** --- Checking if it is existing group or a new one.
                 * Depending on this condition we filter the options - Active programs or all programs --- */
                options={lesson ? programs.content : getActivePrograms(programs.content)}
                loading={programsLoading}
                onInputChange={(event, value) => {
                  setSearchValue(value);
                }}
                placeholder={t('base.placeholders.typeForSearch')}
              />
              <FormikAutocomplete
                name="module"
                label={t('base.labels.module')}
                options={values.program ? values.program.modules : []}
                getCustomLabel={getNameAndNumber}
                placeholder={t('base.placeholders.typeForSearch')}
              />
            </FormColumnWrapper>
            <FormColumnWrapper sx={{ width: '50%' }}>
              <FormColumnTitle>{t('types.lesson.details')}</FormColumnTitle>
              <FormikAutocomplete name="status" label={t('base.labels.status')} options={enums.activeStatuses} />
              <FormikTextField
                name="plannedDuration"
                label={t('base.labels.plannedDuration')}
                placeholder={t('base.placeholders.plannedDuration')}
              />
            </FormColumnWrapper>
          </FormWrapper>
        </Form>
      </PermissionContextProvider>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ lesson = {} }) => ({
      ...lesson,
      id: lesson?.id,
      number: lesson?.number || '',
      name: lesson?.name || '',
      type: lesson?.type || null,
      program: lesson?.program || null,
      plannedDuration: lesson?.plannedDuration || '',
      status: lesson?.status || null,
      module: lesson?.module || null,
    }),
    validationSchema: validationLessonForm,

    handleSubmit: async (values, { props, setSubmitting }) => {
      const res = props.lesson
        ? store.dispatch(lessonThunks.updateLesson(values))
        : store.dispatch(lessonThunks.createLesson(values));

      if (!res.error) {
        toast.success(
          props.lesson ? props.t('messages.success.toast.updateLesson') : props.t('messages.success.toast.createLesson')
        );

        setSubmitting(false);

        if (props.lesson) {
          props.closeModal();
        } else props.navigate(`/lessons/${res.payload.id}`);
      }
    },
    enableReinitialize: true,
  })(LessonForm)
);
