import { Typography } from '@mui/material';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { reportSelectors } from 'store/ducks/report';
import { getShortName } from 'utils/dataTable';
import DataTable from 'views/common/DataTable';
import { DataTableWrapper } from 'views/common/StyledComponents';

const TrainersSessionStatisticReport = () => {
  const trainersSessions = useSelector(reportSelectors.getTrainerSessionsStatistic());
  const trainersSessionsLoading = useSelector(reportSelectors.trainerSessionsStatisticLoading()) && !trainersSessions;
  const { t } = useTranslation();

  const [, setProps] = useOutletContext();

  useEffect(() => {
    setProps((prevProps) => ({
      ...prevProps,
      trainerSessionsStatistic: true,
    }));

    return () => {
      setProps((prevProps) => ({
        ...prevProps,
        trainerSessionsStatistic: false,
      }));
    };
  }, [setProps]);
  const columns = [
    {
      field: 'trainer',
      headerName: t('base.labels.trainer'),
      filterable: false,
      renderCell: ({ row: { trainer } }) => {
        return <Typography>{getShortName(trainer)}</Typography>;
      },
      flex: 0.5,
    },
    {
      field: 'planned',
      headerName: t('types.report.planned'),
      filterable: false,
      flex: 0.2,
    },
    {
      field: 'actual',
      headerName: t('types.report.actual'),
      filterable: false,
      flex: 0.2,
    },
  ];

  return (
    <DataTableWrapper sx={{ height: '100%' }}>
      <DataTable
        list={trainersSessions}
        totalElements={trainersSessions.length}
        isLoading={trainersSessionsLoading}
        columns={columns}
        getRowId={(row) => `${row.trainer.id}`}
        autoPageSize
        hideFooter
      />
    </DataTableWrapper>
  );
};

export default memo(TrainersSessionStatisticReport);
