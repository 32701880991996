import { Document, Paragraph, TextRun, AlignmentType, SectionType } from 'docx';

import { createGradesTable } from './createGradesTable';

import { getFullName } from 'utils/dataTable';

const generateStudentGrades = (student, program, grades) => {
  const doc = new Document({
    styles: {
      default: {
        document: {
          run: {
            size: '11pt',
            font: 'Calibri',
          },
        },
      },
    },
    sections: [
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [new TextRun({ text: `Student: ${getFullName(student)}`, bold: true })],
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [new TextRun({ text: `Program: ${program?.name}`, bold: true })],
          }),
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [new TextRun({ text: `Description: ${program?.description}`, bold: true })],
          }),
        ],
      },
      {
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          new Paragraph({
            spacing: {
              before: 150,
              after: 100,
            },
            alignment: AlignmentType.CENTER,
            children: [new TextRun({ text: 'Grades table', bold: true })],
          }),
          createGradesTable(grades),
        ],
      },
    ],
  });

  return doc;
};

export default generateStudentGrades;
