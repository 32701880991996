import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import { ALLOWED_PERMISSION, PERMISSIONS } from './permissions';
import { TextIncreaseOutlined } from '@mui/icons-material';

export const REPORTS_MENU = [
  [
    {
      key: 'dashboard',
      permission: ALLOWED_PERMISSION,
      to: '/reports/dashboard',
      icon: <DashboardCustomizeOutlinedIcon />,
    },
  ],
  [
    {
      key: 'contractsWOgroup',
      permission: PERMISSIONS.contract.read,
      to: '/reports/contracts/no-group',
      icon: <NoteAltOutlinedIcon />,
    },
    {
      key: 'contractsPausedActiveInvoices',
      permission: PERMISSIONS.contract.read,
      to: '/reports/contracts/paused',
      icon: <NoteAltOutlinedIcon />,
    },
    {
      key: 'contractsCancelledActiveInvoices',
      permission: PERMISSIONS.contract.read,
      to: '/reports/contracts/cancelled',
      icon: <NoteAltOutlinedIcon />,
    },
    {
      key: 'contractsWOdocs',
      permission: PERMISSIONS.contract.read,
      to: '/reports/contracts/no-docs',
      icon: <NoteAltOutlinedIcon />,
    },
  ],
  [
    {
      key: 'invoicesForPeriod',
      permission: PERMISSIONS.invoice.read,
      to: '/reports/invoices/period',
      icon: <RequestQuoteOutlinedIcon />,
    },
    {
      key: 'invoicesOverdue',
      permission: PERMISSIONS.invoice.read,
      to: '/reports/invoices/overdue',
      icon: <RequestQuoteOutlinedIcon />,
    },
  ],
  [
    {
      key: 'sessionsDuration',
      permission: PERMISSIONS.session.read,
      to: '/reports/sessions/trainer-sessions',
      icon: <HistoryEduOutlinedIcon />,
    },
    {
      key: 'trainerSessionsStatistic',
      permission: PERMISSIONS.session.read,
      to: '/reports/sessions/total-trainer-sessions',
      icon: <HistoryEduOutlinedIcon />,
    },
  ],
  [
    {
      key: 'studentGrades',
      permission: PERMISSIONS.grade.read,
      to: '/reports/student-grades',
      icon: <TextIncreaseOutlined />,
    },
  ],
];
