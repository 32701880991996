import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const personSlice = createSlice({
  name: 'personSlice',
  initialState: {
    list: loadDataPaginatedState(),
    item: loadDataInitialState(null),
    userContracts: loadDataInitialState([]),
    userInvoices: loadDataInitialState([]),
    userGroups: loadDataInitialState([]),
    userGrades: loadDataInitialState([]),
    clients: loadDataPaginatedState(),
  },
  reducers: {
    replacePerson(state, action) {
      const index = state.list.data.content.findIndex((i) => i.id === action.payload.data.id);
      state.list.data.content[index] = action.payload.data;
    },
    clearItemWithInitialState(state, action) {
      state[action.payload.collection] = loadDataInitialState(action.payload.data);
    },

    setClients(state, action) {
      state.clients.data.content = action.payload;
    },
    setPersons(state, action) {
      state.list.data.content = action.payload;
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchPersons, 'list');
    loadDataExtraReducer(builder, thunks.fetchClients, 'clients');
    loadDataExtraReducer(builder, thunks.fetchPersonContracts, 'userContracts');
    loadDataExtraReducer(builder, thunks.fetchPersonInvoices, 'userInvoices');
    loadDataExtraReducer(builder, thunks.fetchPersonGroups, 'userGroups');
    loadDataExtraReducer(builder, thunks.fetchPersonGrades, 'userGrades');
    loadDataExtraReducer(builder, thunks.fetchPerson, 'item');
    loadDataExtraReducer(builder, thunks.createPerson, 'item');
    loadDataExtraReducer(builder, thunks.updatePerson, 'item');
  },
});

export const { replacePerson, clearPersonGroups, setClients, setPersons, clearItemWithInitialState } =
  personSlice.actions;
export default personSlice.reducer;
