import { memo } from 'react';
import { Stack, Typography } from '@mui/material';

import { getShortName } from 'utils/dataTable';

const RenderLegalEntityItem = ({ item }) => {
  return (
    <Stack gap={0.5} width="100%">
      <Stack direction="row" alignItems="center" gap={3} justifyContent="space-between">
        <Typography variant="body2" color="text.main" fontWeight={600}>
          {item.name}
        </Typography>
        <Typography variant="body2" color="text.main">
          {item.idCode}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={3} justifyContent="space-between">
        <Typography variant="body2" color="text.main">
          {item.bank}
        </Typography>
        <Typography variant="body2" color="text.main">
          {item.iban}
        </Typography>
      </Stack>
      <Typography variant="body2" color="text.main">
        {item.address}
      </Typography>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2" color="text.main">
          {getShortName(item?.owner)}
        </Typography>
        {item.documents.length > 0 &&
          item.documents.map((doc) => (
            <Typography variant="body2" color="text.main" key={doc}>
              <a style={{ textDecoration: 'none' }} href={doc} target="_blank" rel="noreferrer">
                {doc}
              </a>
            </Typography>
          ))}
      </Stack>
    </Stack>
  );
};

export default memo(RenderLegalEntityItem);
