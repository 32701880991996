import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import store from 'store';
import { programActions, programSelectors, programThunks } from 'store/ducks/program';

import Loader from 'views/common/Loader';
import Error from 'views/common/Error';
import ProgramForm from './components/ProgramForm';

import useModal from 'hooks/useModal';

const ProgramCard = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [modalOpen, openModal, closeModal] = useModal();
  const program = useSelector(programSelectors.getProgram());
  const isLoading = useSelector(programSelectors.programLoading()) && !program;

  const [file, setFile] = useState(null);

  useEffect(() => {
    store.dispatch(programThunks.fetchProgram(id));

    return () => store.dispatch(programActions.clearItemWithInitialState({ collection: 'item', data: null }));
  }, [id]);

  return (
    <Stack justifyContent="center">
      {isLoading && <Loader />}
      {!isLoading && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <ProgramForm
            program={program}
            modalOpen={modalOpen}
            closeModal={closeModal}
            openModal={openModal}
            file={file}
            setFile={setFile}
            t={t}
          />
        </ErrorBoundary>
      )}
    </Stack>
  );
};

export default memo(ProgramCard);
