import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const lessonSlice = createSlice({
  name: 'lessonSlice',
  initialState: {
    list: loadDataPaginatedState(),
    item: loadDataInitialState(null),
    lessonAssignments: loadDataInitialState([]),
  },
  reducers: {
    replaceLesson(state, action) {
      const index = state.list.data.content.findIndex((i) => i.id === action.payload.data.id);
      state.list.data.content[index] = action.payload.data;
    },
    clearItemWithInitialState(state, action) {
      state[action.payload.collection] = loadDataInitialState(action.payload.data);
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchLessons, 'list');
    loadDataExtraReducer(builder, thunks.fetchLessonAssignments, 'lessonAssignments');
    loadDataExtraReducer(builder, thunks.fetchLesson, 'item');
    loadDataExtraReducer(builder, thunks.createLesson, 'item');
    loadDataExtraReducer(builder, thunks.updateLesson, 'item');
  },
});

export const { replaceLesson, clearItemWithInitialState } = lessonSlice.actions;
export default lessonSlice.reducer;
