import { useState } from 'react';
import { utils, write } from 'xlsx';
import { getFullName } from './dataTable';
import store from 'store';
import { uploadThunks } from 'store/ducks/upload';

const navigator = window.navigator || {};

const fallbackCopyTextToClipboard = async (text) => {
  const textArea = document.createElement('input');
  textArea.style.position = 'fixed';
  textArea.style.top = '0';
  textArea.style.left = '-9999px';
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  textArea.setSelectionRange(0, 99999);

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
    return msg;
  } catch (err) {
    console.err('Unable to copy to clipboard'); //eslint-disable-line
  }

  document.body.removeChild(textArea); //eslint-disable-line
  return 'success';
};

/** --- Copy to Clipboard --- */
export function useCopyToClipboard() {
  const [copyResult, setCopyResult] = useState(null);

  const copy = async (text) => {
    try {
      if (!navigator.clipboard) {
        const msg = await fallbackCopyTextToClipboard(text);
        setCopyResult({ state: 'success', message: msg });
      } else await navigator.clipboard.writeText(text);
      setCopyResult({ state: 'success' });
    } catch (e) {
      setCopyResult({ state: 'error', message: e.message });
      throw e;
    } finally {
      // 👇 Show the result feedback for 2 seconds
      setTimeout(() => {
        setCopyResult(null);
      }, 2000);
    }
  };

  // 👇 We want the result as a tuple
  return [copy, copyResult];
}
/** --- Get data from local storage --- */
export const getDataFromLS = (key) => {
  const lsData = localStorage.getItem(key);
  if (!lsData) return '';
  try {
    const value = JSON.parse(lsData);
    return value;
  } catch (e) {
    return '';
  }
};

// TODO: remove once it will be implemented at backend 👇
/** ----Export data to Excel --- */

// --- Checking and processing nested  arrays and objects  ---
const flattenData = (data) => {
  const flattenedData = [];
  if (Array.isArray(data)) {
    data.forEach((item) => {
      const flattenedItem = flattenObject(item);
      flattenedData.push(flattenedItem);
    });
  } else {
    const flattenedItem = flattenObject(data);
    flattenedData.push(flattenedItem);
  }

  return flattenedData;
};

/** --- Object with possible nested objects which defines the property we should use for export ---  */
const CHILD_OBJECT = {
  status: 'label',
  gender: 'label',
  format: 'label',
  roles: 'label',
  language: 'label',
  paymentMethod: 'label',
  schedule: 'label',
  type: 'label',
  requirement: 'label',
  contract: 'number',
  legalEntity: 'name',
  modules: 'name',
  lesson: 'name',
  program: 'name',
  group: 'name',
  assignment: 'name',
  module: 'name',
  planned: 'name',
  actual: 'name',
};

const flattenObject = (obj) => {
  const flattenedObj = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (Array.isArray(value)) {
      if (value.length > 0 && typeof value[0] === 'object') {
        const childNames = value.map((child) => {
          if (typeof child === 'object') {
            return child.lastName ? getFullName(child) : child.label ? child.label : child.name;
          }
          return child;
        });
        flattenedObj[key] = childNames.join(', ');
      } else {
        flattenedObj[key] = value.join(', ');
      }
    } else if (typeof value === 'object' && value !== null) {
      /** --- Define the selector based on object key and match it from child object which property we should use ---  */
      const nameSelector = CHILD_OBJECT[key];
      /** --- Here is the key of object which can not be placed in object because we need to use function to retrieve the name and last name of person --- */
      if (
        key === 'author' ||
        key === 'responsible' ||
        key === 'contractor' ||
        key === 'student' ||
        key === 'coordinator' ||
        key === 'mentor' ||
        key === 'trainer'
      ) {
        flattenedObj[key] = getFullName(value);
        /** --- For simple cases we use Object_child with nameSelector to retrieve needed property --- */
      } else flattenedObj[key] = value[nameSelector];
    } else {
      flattenedObj[key] = value;
    }
  });
  return flattenedObj;
};

export const exportToExcel = (data) => {
  const flattenedData = flattenData(data);
  const worksheet = utils.json_to_sheet(flattenedData);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

  const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
  saveAsExcelFile(excelBuffer, 'data.xlsx');
};

const saveAsExcelFile = (buffer, fileName) => {
  const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const downloadLink = document.createElement('a');
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.download = fileName;
  downloadLink.click();
  URL.revokeObjectURL(url);
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/** --- Function that check if the date in field of form was changed ---  */
export const checkFieldDateForChanges = (initialValue, currentValue) => {
  return new Date(initialValue).getTime() !== new Date(currentValue).getTime();
};

/** --- Function that uploads files and returns an array with uploaded files data --- */
export const uploadFilesAndReturnUploadedFiles = async (files, values) => {
  const uploadedFiles = [];

  await Promise.all(
    files?.map(async (file, index) => {
      /** -- null - is the trigger that we should remove file --- */
      if (file === null) {
        uploadedFiles.push('');
        /** -- check if we have already uploaded file  and there is no updates we just leave the file as it is--- */
      } else if (file === '' && values?.files[index] && file !== null) {
        uploadedFiles.push(values?.files[index]);
        /** -- we upload the file in case if the input is not empty.
         * We always have empty item in array in order to render first upload file input,
         * that's why this condition is needed  --- */
      } else if (file !== '' && file !== undefined) {
        const response = await store.dispatch(uploadThunks.uploadFile(file));
        if (response.meta.requestStatus === 'fulfilled') {
          uploadedFiles.push(response.payload);
        }
      }
    })
  );

  return uploadedFiles;
};
