import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'services/api';
import { addItem, deleteItem, replaceItem } from './slice';

const fetchEnums = createAsyncThunk('dictionary/fetchEnums', () => {
  return api.get('/dictionaries/enums');
});

const fetchEducations = createAsyncThunk('dictionary/fetchEducations', () => {
  return api.get('/dictionaries/educations');
});

const createEducation = createAsyncThunk('dictionary/createEducation', (data, { dispatch }) => {
  return api.post('/dictionaries/educations', data).then((res) => {
    dispatch(addItem({ collection: 'educations', data: res }));
    return res;
  });
});

const updateEducation = createAsyncThunk('dictionary/updateEducation', (data, { dispatch }) => {
  return api.put(`/dictionaries/educations/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: 'educations', data: res }));
    return res;
  });
});

const deleteEducation = createAsyncThunk('dictionary/deleteEducation', (data, { dispatch }) => {
  return api.delete(`/dictionaries/educations/${data.id}`).then((res) => {
    dispatch(deleteItem({ collection: 'educations', data }));
    return res;
  });
});

const fetchUniversities = createAsyncThunk('dictionary/fetchUniversities', () => {
  return api.get('/dictionaries/universities');
});

const createUniversity = createAsyncThunk('dictionary/createUniversity', (data, { dispatch }) => {
  return api.post('/dictionaries/universities', data).then((res) => {
    dispatch(addItem({ collection: 'universities', data: res }));
    return res;
  });
});

const updateUniversity = createAsyncThunk('dictionary/updateUniversity', (data, { dispatch }) => {
  return api.put(`/dictionaries/universities/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: 'universities', data: res }));
    return res;
  });
});

const fetchProfessions = createAsyncThunk('dictionary/fetchProfessions', () => {
  return api.get('/dictionaries/professions');
});

const createProfession = createAsyncThunk('dictionary/createProfession', (data, { dispatch }) => {
  return api.post('/dictionaries/professions', data).then((res) => {
    dispatch(addItem({ collection: 'professions', data: res }));
    return res;
  });
});
const updateProfession = createAsyncThunk('dictionary/updateProfession', (data, { dispatch }) => {
  return api.put(`/dictionaries/professions/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: 'professions', data: res }));
    return res;
  });
});

const fetchRoles = createAsyncThunk('dictionary/fetchRoles', () => {
  return api.get('/dictionaries/roles');
});

const fetchManagers = createAsyncThunk('dictionary/fetchManagers', () => {
  return api.get('/dictionaries/managers');
});

const fetchLegalEntities = createAsyncThunk('dictionary/fetchLegalEntities', () => {
  return api.get('/dictionaries/legal-entities');
});

const createLegalEntity = createAsyncThunk('dictionary/createLegalEntity', (data, { dispatch }) => {
  return api.post('/dictionaries/legal-entities', data).then((res) => {
    dispatch(addItem({ collection: 'legalEntities', data: res }));
    return res;
  });
});
const updateLegalEntity = createAsyncThunk('dictionary/updateLegalEntity', (data, { dispatch }) => {
  return api.put(`/dictionaries/legal-entities/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: 'legalEntities', data: res }));
    return res;
  });
});

const fetchTrainers = createAsyncThunk('dictionary/fetchTrainers', () => {
  return api.get('/dictionaries/trainers');
});

const fetchMentors = createAsyncThunk('dictionary/fetchMentors', () => {
  return api.get('/dictionaries/mentors');
});
const fetchCoordinators = createAsyncThunk('dictionary/fetchCoordinators', () => {
  return api.get('/dictionaries/coordinators');
});

const fetchEmployees = createAsyncThunk('dictionary/fetchEmployees', () => {
  return api.get('/dictionaries/employees');
});

/**------------- TASK TYPES ------------------- */
const fetchTaskTypes = createAsyncThunk('dictionary/fetchTaskTypes', () => {
  return api.get('/dictionaries/task-types');
});

const createTaskTypes = createAsyncThunk('dictionary/createTaskTypes', (data, { dispatch }) => {
  return api.post('/dictionaries/task-types', data).then((res) => {
    dispatch(addItem({ collection: 'taskTypes', data: res }));
    return res;
  });
});

const updateTaskTypes = createAsyncThunk('dictionary/updateTaskTypes', (data, { dispatch }) => {
  return api.put(`/dictionaries/task-types/${data.id}`, data).then((res) => {
    dispatch(replaceItem({ collection: 'taskTypes', data: res }));
    return res;
  });
});

export default {
  fetchEnums,
  fetchEducations,
  createEducation,
  updateEducation,
  deleteEducation,
  fetchUniversities,
  fetchProfessions,
  fetchRoles,
  fetchManagers,
  fetchLegalEntities,
  fetchTrainers,
  fetchMentors,
  fetchCoordinators,
  createLegalEntity,
  updateLegalEntity,
  createProfession,
  updateProfession,
  createUniversity,
  updateUniversity,
  fetchEmployees,
  fetchTaskTypes,
  createTaskTypes,
  updateTaskTypes,
};
