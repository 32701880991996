import { Autocomplete, TextField } from '@mui/material';
import { memo, useState } from 'react';
import store from 'store';
import { getShortName } from 'utils/dataTable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';

const FilterByPerson = ({ person, options, action, label }) => {
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  const handleOnChange = (newValue) => {
    store.dispatch(action(newValue));
  };

  return (
    <Autocomplete
      value={person ? options.find((option) => option.id === person.id) : null}
      onChange={(event, newValue) => {
        handleOnChange(newValue);
      }}
      inputValue={inputValue}
      getOptionLabel={(option) => getShortName(option)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {getShortName(option)}
          </li>
        );
      }}
      popupIcon={<KeyboardArrowDownIcon sx={{ color: 'primary.lighter' }} />}
      sx={{ width: 320, '& .MuiOutlinedInput-root': { py: 0.5 } }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={t('base.placeholders.choose')}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& ::placeholder': {
                color: 'primary.main',
              },
            },
          }}
        />
      )}
    />
  );
};

export default memo(FilterByPerson);
