
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { PageWrapper } from 'views/common/StyledComponents';

import HeaderReports from 'views/pages/reports/components/HeaderReports';

const ReportsLayout = () => {
  const [props, setProps] = useState({
    overdueInvoicesReport: false,
    sessionsDurationReport: false,
    invoicesForPeriod: false,
    handleGenerateReport: null,
    studentGradesReport: false,
    wordDoc: null,
    trainerSessionsStatistic: false
  });

  return (
    <PageWrapper>
      <HeaderReports
        overdueInvoicesReport={props.overdueInvoicesReport}
        sessionsDurationReport={props.sessionsDurationReport}
        invoicesForPeriod={props.invoicesForPeriod}
        handleGenerateReport={props.handleGenerateReport}
        studentGradesReport={props.studentGradesReport}
        wordDoc={props.wordDoc}
        trainerSessionsStatistic={props.trainerSessionsStatistic}
      />
      <Outlet context={[props, setProps]} />
    </PageWrapper>
  );
};

export default ReportsLayout;
