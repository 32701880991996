import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ListItem, ListItemText, Button, Stack } from '@mui/material';
import { Delete, EditOutlined } from '@mui/icons-material';

import useModal from 'hooks/useModal';
import Modal from 'views/common/modal/Modal';

import store from 'store';
import useQuickAddInput from 'hooks/useQuickAddInput';
import QuickAddInput from 'views/common/QuickAddInput';
import { AttentionIcon } from 'theme/icons';
import { DictionaryListItemButton, DictionaryListItemIcon } from 'views/common/StyledComponents';

const DictionaryItemCard = ({ item, selectedDictionary, showItemButton }) => {
  const { t } = useTranslation();
  const [editMode, newValue, setNewValue, enableEditMode, disableEditMode, isLoading, setIsLoading] =
    useQuickAddInput();

  const [modalOpen, openModal, closeModal] = useModal();

  const handleModalOpen = (e) => {
    e.stopPropagation();
    openModal();
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    setIsLoading(true);
    const response = await store.dispatch(selectedDictionary.deleteItem(item));
    if (!response.error) {
      toast.success(t('messages.success.toast.delete'));
      closeModal();
    }
    setIsLoading(false);
  };

  const handleUpdate = async () => {
    setIsLoading(true);

    const response = await store.dispatch(selectedDictionary.updateItem({ ...item, name: newValue }));

    if (!response.error) {
      setIsLoading(false);
      toast.success(t('messages.success.toast.update'));
    }
  };

  const handleEditClick = () => {
    enableEditMode(item.name);
  };

  return (
    <>
      {editMode ? (
        <Stack pl={showItemButton ? 2 : 0} pr={1}>
          <QuickAddInput
            newValue={newValue}
            setNewValue={setNewValue}
            disableEditMode={disableEditMode}
            handleActionSubmit={handleUpdate}
            placeholder={t(
              t(`base.placeholders.dictionaryNew`) +
                ' ' +
                t(`types.settings.dictionaries.listSingle.${selectedDictionary?.name}`)
            )}
            isLoading={isLoading}
            size="largeSize"
          />
        </Stack>
      ) : (
        <ListItem
          disableGutters
          sx={{
            p: 0,
            '& .MuiListItemText-root': {
              color: 'text.main',
              transition: 'color 0.3s',
            },
            '&:hover': {
              '& .MuiListItemText-root': { color: 'primary.main' },
            },
          }}
        >
          <ListItemText> {item.name}</ListItemText>
          <DictionaryListItemButton>
            <DictionaryListItemIcon onClick={handleEditClick}>
              <EditOutlined fontSize="small" />
            </DictionaryListItemIcon>
          </DictionaryListItemButton>
          {selectedDictionary.deleteItem && (
            <DictionaryListItemButton
              sx={{
                '&:hover': {
                  '& .MuiListItemIcon-root': { color: 'error.main' },
                },
              }}
            >
              <DictionaryListItemIcon onClick={handleModalOpen}>
                <Delete fontSize="small" />
              </DictionaryListItemIcon>
            </DictionaryListItemButton>
          )}
        </ListItem>
      )}

      <Modal
        title={t(`messages.warning.delete`) + ' ' + item.name}
        open={modalOpen}
        onClose={closeModal}
        icon={<AttentionIcon />}
        actions={
          <>
            <Button
              sx={{ px: 8 }}
              onClick={(e) => {
                e.stopPropagation();
                closeModal();
              }}
            >
              {t('base.buttons.cancel')}
            </Button>
            <Button
              variant="contained"
              sx={{ px: 8 }}
              type="button"
              onClick={(e) => handleDelete(e)}
              disabled={isLoading}
              color="error"
            >
              {t('base.buttons.delete')}
            </Button>
          </>
        }
      />
    </>
  );
};

export default memo(DictionaryItemCard);
