import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import AuthCheck from './AuthCheck';
import Login from 'views/pages/auth/Login';
import AuthContainer from 'views/containers/AuthContainer';
import ForgotPassword from '../views/pages/auth/ForgotPassword';
import ResetPassword from '../views/pages/auth/ResetPassword';
import Page404 from '../views/pages/error/Page404';
import PageLayout from 'views/layouts/PageLayout';
import PersonsPage from 'views/pages/persons/PersonsPage';
import PersonCard from 'views/pages/persons/PersonCard';
import NewPerson from 'views/pages/persons/NewPerson';
import ContractsPage from 'views/pages/contracts/ContractsPage';
import ContractCard from 'views/pages/contracts/ContractCard';
import NewContract from 'views/pages/contracts/NewContract';
import InvoicesPage from 'views/pages/invoices/InvoicesPage';
import InvoiceCard from 'views/pages/invoices/InvoiceCard';
import NewInvoice from 'views/pages/invoices/NewInvoice';
import GradesPage from 'views/pages/grades/GradesPage';
import GradeCard from 'views/pages/grades/GradeCard';
import GroupsPage from 'views/pages/groups/GroupsPage';
import GroupCard from 'views/pages/groups/GroupCard';
import NewGroup from 'views/pages/groups/NewGroup';
import SessionsPage from 'views/pages/sessions/SessionsPage';
import SessionCard from 'views/pages/sessions/SessionCard';
import NewSession from 'views/pages/sessions/NewSession';
import ProgramsPage from 'views/pages/programs/ProgramsPage';
import ProgramCard from 'views/pages/programs/ProgramCard';
import NewProgram from 'views/pages/programs/NewProgram';
import LessonsPage from 'views/pages/lessons/LessonsPage';
import LessonCard from 'views/pages/lessons/LessonCard';
import NewLesson from 'views/pages/lessons/NewLesson';
import TasksPage from 'views/pages/tasks/TasksPage';
import TaskCard from 'views/pages/tasks/TaskCard';
import NewTask from 'views/pages/tasks/NewTask';
import ProtectedRoute from 'routes/ProtectedRoute';
import { PERMISSIONS } from 'utils/constants/permissions';
import ProfilePage from 'views/pages/settings/profile/ProfilePage';
import SettingsLayout from 'views/layouts/SettingsLayout';
import DictionariesPage from 'views/pages/settings/dictionaries/DictionariesPage';
import PermissionsPage from 'views/pages/settings/permissions/PermissionsPage';
import LogsPage from 'views/pages/settings/logs/LogsPage';
import AssignmentsPage from 'views/pages/assignments/AssignmentsPage';
import AssignmentCard from 'views/pages/assignments/AssignmentCard';
import ReportsLayout from 'views/layouts/ReportsLayout';
import Dashboard from 'views/pages/reports/dashboard/Dashboard';
import NoGroupReport from 'views/pages/reports/contracts/NoGroupReport';
import PausedWithActiveInvoicesReport from 'views/pages/reports/contracts/PausedWithActiveInvoicesReport';
import CancelledWithActiveInvoicesReport from 'views/pages/reports/contracts/CancelledWithActiveInvoicesReport';
import NoDocsReport from 'views/pages/reports/contracts/NoDocsReport';
import InvoicesForPeriodReport from 'views/pages/reports/invoices/InvoicesForPeriodReport';
import OverdueInvoicesReport from 'views/pages/reports/invoices/OverdueInvoicesReport';
import SessionsDuration from 'views/pages/reports/sessions/SessionsDurationReport';
import NewBulkSessionsPage from 'views/pages/sessions/NewBulkSessionsPage';
import StudentGradesReport from 'views/pages/reports/grades/StudentGradesReport';
import TrainersSessionStatisticReport from 'views/pages/reports/sessions/TrainersSessionStatisticReport';

const router = createBrowserRouter(
  createRoutesFromElements([
    <Route
      key="AuthCheck"
      path="/"
      element={
        <AuthCheck>
          <AuthContainer />
        </AuthCheck>
      }
    >
      <Route path="/" element={<PageLayout />} exact>
        <Route index element={<Navigate to="/tasks" replace />} />
        <Route path="tasks" element={<TasksPage />} />
        <Route path="tasks/:id" element={<TaskCard />} />
        <Route
          path="tasks/new"
          element={
            <ProtectedRoute permission={PERMISSIONS.task.create}>
              <NewTask />
            </ProtectedRoute>
          }
        />

        <Route
          path="persons"
          element={
            <ProtectedRoute permission={PERMISSIONS.person.read}>
              <PersonsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="persons/:id"
          element={
            <ProtectedRoute permission={PERMISSIONS.person.read}>
              <PersonCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="persons/new"
          element={
            <ProtectedRoute permission={PERMISSIONS.person.create}>
              <NewPerson />
            </ProtectedRoute>
          }
        />

        <Route
          path="contracts"
          element={
            <ProtectedRoute permission={PERMISSIONS.contract.read}>
              <ContractsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="contracts/:id"
          element={
            <ProtectedRoute permission={PERMISSIONS.contract.read}>
              <ContractCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="contracts/new"
          element={
            <ProtectedRoute permission={PERMISSIONS.contract.create}>
              <NewContract />
            </ProtectedRoute>
          }
        />

        <Route
          path="invoices"
          element={
            <ProtectedRoute permission={PERMISSIONS.invoice.read}>
              <InvoicesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="invoices/:id"
          element={
            <ProtectedRoute permission={PERMISSIONS.invoice.read}>
              <InvoiceCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="invoices/new"
          element={
            <ProtectedRoute permission={PERMISSIONS.invoice.create}>
              <NewInvoice />
            </ProtectedRoute>
          }
        />

        <Route
          path="assignments"
          element={
            <ProtectedRoute permission={PERMISSIONS.assignment.read}>
              <AssignmentsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="assignments/:id"
          element={
            <ProtectedRoute permission={PERMISSIONS.assignment.read}>
              <AssignmentCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="grades"
          element={
            <ProtectedRoute permission={PERMISSIONS.grade.read}>
              <GradesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="grades/:id"
          element={
            <ProtectedRoute permission={PERMISSIONS.grade.read}>
              <GradeCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="sessions"
          element={
            <ProtectedRoute permission={PERMISSIONS.session.read}>
              <SessionsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="sessions/:id"
          element={
            <ProtectedRoute permission={PERMISSIONS.session.read}>
              <SessionCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="sessions/new"
          element={
            <ProtectedRoute permission={PERMISSIONS.session.create}>
              <NewSession />
            </ProtectedRoute>
          }
        />
        <Route
          path="sessions/bulk"
          element={
            <ProtectedRoute permission={PERMISSIONS.session.create}>
              <NewBulkSessionsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="groups"
          element={
            <ProtectedRoute permission={PERMISSIONS.group.read}>
              <GroupsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="groups/:id"
          element={
            <ProtectedRoute permission={PERMISSIONS.group.read}>
              <GroupCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="groups/new"
          element={
            <ProtectedRoute permission={PERMISSIONS.group.create}>
              <NewGroup />
            </ProtectedRoute>
          }
        />
        <Route
          path="programs"
          element={
            <ProtectedRoute permission={PERMISSIONS.program.read}>
              <ProgramsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="programs/:id"
          element={
            <ProtectedRoute permission={PERMISSIONS.program.read}>
              <ProgramCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="programs/new"
          element={
            <ProtectedRoute permission={PERMISSIONS.program.create}>
              <NewProgram />
            </ProtectedRoute>
          }
        />
        <Route
          path="lessons"
          element={
            <ProtectedRoute permission={PERMISSIONS.lesson.read}>
              <LessonsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="lessons/:id"
          element={
            <ProtectedRoute permission={PERMISSIONS.lesson.read}>
              <LessonCard />
            </ProtectedRoute>
          }
        />
        <Route
          path="lessons/new"
          element={
            <ProtectedRoute permission={PERMISSIONS.lesson.create}>
              <NewLesson />
            </ProtectedRoute>
          }
        />

        {/** --- REPORTS --- */}
        <Route path="reports" element={<ReportsLayout />} exact>
          <Route index element={<Navigate to="dashboard" />} replace />

          <Route path="dashboard" element={<Dashboard />} />

          {/** --- CONTRACTS REPORTS ---- */}
          <Route
            path="contracts/no-group"
            element={
              <ProtectedRoute permission={PERMISSIONS.contract.read}>
                <NoGroupReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="contracts/paused"
            element={
              <ProtectedRoute permission={PERMISSIONS.contract.read}>
                <PausedWithActiveInvoicesReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="contracts/cancelled"
            element={
              <ProtectedRoute permission={PERMISSIONS.contract.read}>
                <CancelledWithActiveInvoicesReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="contracts/no-docs"
            element={
              <ProtectedRoute permission={PERMISSIONS.contract.read}>
                <NoDocsReport />
              </ProtectedRoute>
            }
          />

          {/** --- INVOICES REPORTS ---- */}
          <Route
            path="invoices/period"
            element={
              <ProtectedRoute permission={PERMISSIONS.invoice.read}>
                <InvoicesForPeriodReport />
              </ProtectedRoute>
            }
          />
          <Route
            path="invoices/overdue"
            element={
              <ProtectedRoute permission={PERMISSIONS.invoice.read}>
                <OverdueInvoicesReport />
              </ProtectedRoute>
            }
          />

          {/** --- SESSIONS REPORTS ---- */}
          <Route path="sessions/trainer-sessions" element={<SessionsDuration />} />
          <Route path="sessions/total-trainer-sessions" element={<TrainersSessionStatisticReport />} />

          {/** --- GRADES REPORTS ---- */}
          <Route path="student-grades" element={<StudentGradesReport />} />
        </Route>
        <Route path="settings" element={<SettingsLayout />} exact>
          <Route index element={<Navigate to="profile" />} replace />
          <Route path="profile" element={<ProfilePage />} />
          <Route
            path="dictionaries"
            element={
              <ProtectedRoute>
                <DictionariesPage permission={PERMISSIONS.dictionary.create} />
              </ProtectedRoute>
            }
          />
          <Route
            path="permissions"
            element={
              <ProtectedRoute>
                <PermissionsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="logs"
            element={
              <ProtectedRoute>
                <LogsPage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route key="notFound" path="*" element={<Page404 />} />
      </Route>
    </Route>,
    <Route key="login" path="/login" element={<Login />} exact />,
    <Route key="forgot" path="/forgot" element={<ForgotPassword />} exact />,
    <Route key="reset" path="/reset/:token" element={<ResetPassword />} exact />,
  ])
);

export default router;
