export const getFullName = (option) => {
  return option ? option?.contact?.lastName + ' ' + option?.contact?.name + (option.contact?.secondName ? ' ' + option.contact?.secondName : '') : '';
};

export const getLastNameAndNameLetter = (option) => {
  return option?.contact ? option.contact.lastName + ' ' + option.contact.name[0] + '.' : '';
};
export const getLastNameAndNameAndSecondNameLetter = (option) => {
  if (!option?.contact) {
    return '';
  }

  const lastName = option.contact.lastName || '';
  const nameInitial = option.contact.name ? option.contact.name[0] + '.' : '';
  const secondNameInitial = option.contact.secondName ? option.contact.secondName[0] + '.' : '';

  return `${lastName} ${nameInitial} ${secondNameInitial}`.trim();
};
export const getShortName = (option) => {
  return option?.contact ? option.contact.lastName + ' ' + option.contact.name : '';
};

export const getActiveGroupMember = (members) => {
  return members.find((member) => !member.endDate);
};

export const getProgramHours = (programs, values) => {
  const program = programs.content.find((option) => values?.program?.id === option.id);
  const hours = program ? program?.hours : '';
  return hours;
};

export const getActivePrograms = (programs) => {
  return programs.filter((program) => program.status.name !== 'ARCHIVED');
};

export const getActiveGroups = (groups) => {
  return groups.filter((group) => group.status.name !== 'DISBANDED' && group.status.name !== 'GRADUATED');
};

export const getActiveContracts = (contracts) => {
  return contracts.filter((contract) => contract.status.name !== 'CANCELLED' && contract.status.name !== 'COMPLETED');
};

export const getActiveAssignments = (assignments) => {
  return assignments.filter((assignment) => assignment.status.name !== 'ARCHIVED');
};


export const getNameAndNumber = (option) => {
  return option ? '№' + ' ' + option.number + '.' + ' ' + option.name : '';
};
