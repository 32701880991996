const getContractsReportNoGroup = () => (state) => state.report.contractReport.data;
const contractsReportNoGroupLoading = () => (state) => state.report.contractReport.isLoading;
const getContractsReportNoGroupError = () => (state) => state.report.contractReport.error;
const getPausedContractsActiveInvoices = () => (state) => state.report.contractReport.data;
const pausedContractsReportActiveInvoicesLoading = () => (state) => state.report.contractReport.isLoading;
const getPausedContractsReportActiveInvoicesError = () => (state) => state.report.contractReport.error;
const getCancelledContractsActiveInvoices = () => (state) => state.report.contractReport.data;
const cancelledContractsReportActiveInvoicesLoading = () => (state) => state.report.contractReport.isLoading;
const getCancelledContractsReportActiveInvoicesError = () => (state) => state.report.contractReport.error;
const getContractsReportNoDocs = () => (state) => state.report.contractReport.data;
const contractsReportNoDocsLoading = () => (state) => state.report.contractReport.isLoading;
const getContractsReportNoDocsError = () => (state) => state.report.contractReport.error;
const getInvoicesForPeriodReport = () => (state) => state.report.invoiceReport.data;
const invoicesForPeriodReportLoading = () => (state) => state.report.invoiceReport.isLoading;
const getInvoicesForPeriodReportError = () => (state) => state.report.invoiceReport.error;
const getOverdueInvoicesReport = () => (state) => state.report.invoiceReport.data;
const overdueInvoicesReportLoading = () => (state) => state.report.invoiceReport.isLoading;
const getOverdueInvoicesReportError = () => (state) => state.report.invoiceReport.error;
const getSessionsDurationReport = () => (state) => state.report.sessionReport.data;
const sessionsDurationReportLoading = () => (state) => state.report.sessionReport.isLoading;
const getSessionsDurationReportError = () => (state) => state.report.sessionReport.error;
const getFilterByTrainer = () => (state) => state.report.filterByTrainer;
const getFilterByManager = () => (state) => state.report.filterByManager;
const getFilterByPeriod = () => (state) => state.report.filterByPeriod;
const getQuery = () => (state) => state.report.query;
const getSessionsDuration = () => (state) => state.report.sessionsDuration;

const getStudentGrades = () => (state) => state.report.studentGrades.data;
const studentGradesLoading = () => (state) => state.report.studentGrades.isLoading;
const getStudentGradesError = () => (state) => state.report.studentGrades.error;
const getStudentGradesFilterData = () => (state) => state.report.filterStudentGrade;

const getTrainerSessionsStatistic = () => (state) => state.report.trainerSessionsStatistic.data;
const trainerSessionsStatisticLoading = () => (state) => state.report.trainerSessionsStatistic.isLoading;
const getTrainerSessionsStatisticError = () => (state) => state.report.trainerSessionsStatistic.error;

export default {
  getContractsReportNoGroup,
  contractsReportNoGroupLoading,
  getContractsReportNoGroupError,
  getPausedContractsActiveInvoices,
  pausedContractsReportActiveInvoicesLoading,
  getPausedContractsReportActiveInvoicesError,
  getCancelledContractsActiveInvoices,
  cancelledContractsReportActiveInvoicesLoading,
  getCancelledContractsReportActiveInvoicesError,
  getContractsReportNoDocs,
  contractsReportNoDocsLoading,
  getContractsReportNoDocsError,
  getInvoicesForPeriodReport,
  invoicesForPeriodReportLoading,
  getInvoicesForPeriodReportError,
  getOverdueInvoicesReport,
  overdueInvoicesReportLoading,
  getOverdueInvoicesReportError,
  getSessionsDurationReport,
  sessionsDurationReportLoading,
  getSessionsDurationReportError,
  getFilterByTrainer,
  getFilterByManager,
  getFilterByPeriod,
  getQuery,
  getSessionsDuration,
  getStudentGrades,
  studentGradesLoading,
  getStudentGradesError,
  getStudentGradesFilterData,
  getTrainerSessionsStatistic,
  trainerSessionsStatisticLoading,
  getTrainerSessionsStatisticError,
};
