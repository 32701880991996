import { DatePicker } from '@mui/x-date-pickers';
import { Box, ListItemButton, ListItemIcon, Stack, Typography, styled } from '@mui/material';
import { DESKTOP_HEIGHT, MOBILE_HEIGHT, TABLET_HEIGHT } from 'utils/constants/constants';
import { CalendarIcon } from 'theme/icons';


/** --- Form Wrapper --- */

export const FormWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  marginTop: theme.spacing(1),
  gap: theme.spacing(2),
  width: '100%',
}));

export const DataTableWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.extra.white,
  borderRadius: theme.spacing(2),
}));

export const TabPanelsWrapper = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: theme.palette.extra.white,
  borderRadius: theme.spacing(0, 0, 2, 2),
  height: '100%',
  overflowY: 'auto',
}));

/** --- Form's Column Wrapper (applied to form's columns) */

export const FormColumnWrapper = styled(Stack)(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: theme.palette.extra.white,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1.5),
  gap: theme.spacing(1),
}));

/** --- Form's Column's Title */

export const FormColumnTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  lineHeight: 1,
  fontSize: 18,
  fontWeight: 700,
}));

/** --- DatePicker for using outside the forms, in reports for instance --- */

export const PeriodDatePicker = (props) => {
  return (
    <DatePicker
      {...props}
      format="dd.MM.yyyy"
      slots={{
        openPickerIcon: CalendarIcon,
      }}
      slotProps={{
        textField: {
          sx: {
            maxWidth: 154,

            '& .MuiOutlinedInput-root': {
              color: 'primary.main',
              '& ::placeholder': {
                color: 'primary.main',
              },
            },
          },
          variant: 'outlined',
          InputLabelProps: { shrink: true },
        },
      }}
    />
  );
};

export const PageWrapper = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  height: DESKTOP_HEIGHT,
  [theme.breakpoints.down('lg')]: {
    height: TABLET_HEIGHT,
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
    height: MOBILE_HEIGHT,
  },
}));

export const DictionaryListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  borderRadius: '50%',
  maxWidth: 'fit-content',
  '& .MuiListItemIcon-root': {
    color: theme.palette.primary.lighter,
    transition: 'color 0.3s',
  },
  '&:hover': {
    backgroundColor: 'transparent',
    '& .MuiListItemIcon-root': { color: theme.palette.primary.main },
  },
}));

export const DictionaryListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  padding: theme.spacing(0.5),
  minWidth: 0,
}));

