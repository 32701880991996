import { createSlice } from '@reduxjs/toolkit';
import thunks from './thunks';
import loadDataExtraReducer from 'store/utils/loadDataExtraReducer';
import loadDataPaginatedState from '../../utils/loadDataPaginatedState';
import { DateTime } from 'luxon';
import loadDataInitialState from 'store/utils/loadDataInitialState';

const reportSlice = createSlice({
  name: 'reportSlice',
  initialState: {
    contractReport: loadDataPaginatedState(),
    invoiceReport: loadDataPaginatedState(),
    sessionReport: loadDataPaginatedState(),
    filterByManager: null,
    filterByTrainer: null,
    filterByPeriod: {
      from: DateTime.local().startOf('month').toFormat('yyyy-MM-dd'),
      to: DateTime.local().endOf('month').toFormat('yyyy-MM-dd'),
    },
    query: null, // state is using for generating report by button click
    sessionsDuration: loadDataInitialState(null),
    studentGrades: loadDataInitialState([]),
    filterStudentGrade: null,
    trainerSessionsStatistic: loadDataInitialState([]),
  },
  reducers: {
    addFilterByManager: (state, action) => {
      state.filterByManager = action.payload;
    },
    addFilterByTrainer: (state, action) => {
      state.filterByTrainer = action.payload;
    },
    addFilterByPeriod: (state, action) => {
      state.filterByPeriod = action.payload;
    },
    addFilterStudentGrade: (state, action) => {
      state.filterStudentGrade = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },

    clearItemWithInitialState(state, action) {
      state[action.payload.collection] = loadDataInitialState(action.payload.data);
    },

    clearItemWithPaginatedState(state, action) {
      state[action.payload.collection] = loadDataPaginatedState();
    },
  },
  extraReducers: (builder) => {
    loadDataExtraReducer(builder, thunks.fetchContractsNoGroup, 'contractReport');
    loadDataExtraReducer(builder, thunks.fetchPausedContractsActiveInvoices, 'contractReport');
    loadDataExtraReducer(builder, thunks.fetchCancelledContractsActiveInvoices, 'contractReport');
    loadDataExtraReducer(builder, thunks.fetchContractsNoDocs, 'contractReport');
    loadDataExtraReducer(builder, thunks.fetchInvoicesForPeriod, 'invoiceReport');
    loadDataExtraReducer(builder, thunks.fetchOverdueInvoices, 'invoiceReport');
    loadDataExtraReducer(builder, thunks.fetchSessionsWithFilters, 'sessionReport');
    loadDataExtraReducer(builder, thunks.fetchSessionsDuration, 'sessionsDuration');
    loadDataExtraReducer(builder, thunks.fetchStudentGrades, 'studentGrades');
    loadDataExtraReducer(builder, thunks.fetchTrainersSessionsStatistic, 'trainerSessionsStatistic');
  },
});

export const {
  addFilterByTrainer,
  addFilterByManager,
  addFilterByPeriod,
  setQuery,
  addFilterStudentGrade,
  clearItemWithInitialState,
  clearItemWithPaginatedState,
  addItem,
} = reportSlice.actions;
export default reportSlice.reducer;
