import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';

const TotalSessionsDuration = ({ sessionsDuration }) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1.5}
      px={2}
      height={44}
      sx={{ border: '1px solid', borderColor: 'primary.main', borderRadius: 1 }}
    >
      <Stack direction="row" gap={0.5}>
        <Typography color="primary.main">{t('types.report.planned')}:</Typography>
        <Typography color="primary.main" fontWeight={600}>
          {sessionsDuration?.planned} {t('types.report.mins')}
        </Typography>
      </Stack>
      <Stack direction="row" gap={0.5}>
        <Typography color="primary.main">{t('types.report.actual')}:</Typography>
        <Typography color="primary.main" fontWeight={600}>
          {sessionsDuration?.actual} {t('types.report.mins')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default memo(TotalSessionsDuration);
