import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Tab, Tabs } from '@mui/material';

import AssignmentsList from 'views/common/AssignmentsList';
import { TabPanelsWrapper } from 'views/common/StyledComponents';

import { TabPanel, a11yProps } from '../../../../utils/tabsFunc';
import { hasPermission } from 'utils/roles';
import { PERMISSIONS } from 'utils/constants/permissions';
import store from 'store';
import { lessonActions, lessonSelectors, lessonThunks } from 'store/ducks/lesson';
import { useSelector } from 'react-redux';

const SessionDetails = ({ session }) => {
  const { t } = useTranslation();
  const assignments = useSelector(lessonSelectors.getLessonAssignments());
  const assignmentsLoading = useSelector(lessonSelectors.lessonAssignmentsLoading());

  const [tabsValue, setTabsValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabsValue(newValue);
  };

  useEffect(() => {
    session?.lesson &&
      hasPermission(PERMISSIONS.assignment.read) &&
      store.dispatch(lessonThunks.fetchLessonAssignments(session.lesson?.id));
  }, [session?.lesson]);

  useEffect(() => {
    return () => store.dispatch(lessonActions.clearItemWithInitialState({ collection: 'lessonAssignments', data: [] }));
  }, []);

  return (
    <Stack sx={{ height: '100%', overflowY: 'hidden' }}>
      <Tabs value={tabsValue} onChange={handleChange} aria-label="main data">
        <Tab sx={{ width: '100%' }} label={t('types.assignment.namePlural')} {...a11yProps(0)} />
      </Tabs>

      <TabPanelsWrapper>
        <TabPanel value={tabsValue} index={0}>
          <AssignmentsList
            list={assignments}
            totalElements={assignments.length}
            columnVisibilityModel={{
              contractor: false,
            }}
            isLoading={assignmentsLoading}
            hideFooter
            isNested
          />
        </TabPanel>
      </TabPanelsWrapper>
    </Stack>
  );
};

export default memo(SessionDetails);
